import { OrderMatchFlterName } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/OrderMatchTab/OrderMatchesGrid/OrderMatchFilterUtils';
import {
  getDateRangeLabel,
  isDataIncluded,
  isDateIncluded,
  isItemIncluded,
  isSubstringIncluded,
  itemIsInRange,
  itemIsTillDate,
} from 'app/ComponentLibrary/Filter/helper';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import { generateFieldChips } from 'app/components/AmWorkDesk/PlacementsDesk/filterHelper';
import { ITypeAheadOption } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { simpleGroupBy } from 'app/helpers/arrayHelpers';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import {
  getAmnEndOfWeek,
  getAmnStartOfWeek,
  getDateValue,
  getFirstOfMonth,
  getLastOfMonth,
} from 'utils/dates/dateExtraction';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import {
  ICandidateCounts,
  IFilterSpecs,
  IPlacementCounts,
  IPlacementFilterChips,
  recruiterPlacementActions,
} from '../../MyPlacementsDesk/RecruiterPlacements.redux';
import { placementStatus, placementSubStatuses } from '../../MyPlacementsDesk/utils';
import { recPlacementsGridKeys } from '../WorkdeskGrid/helper';
import { IPlacementFilter, recPlacementsFilterName } from './filterUtils';
import { FacilityCommunicationHistoryFiltersrName } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/Filters/FacilityCommunicationHistoryFilterUtils';

const getCandidatesCount = dataArray => (dataArray ? Object.keys(simpleGroupBy(dataArray, 'candidateId')).length : 0);

export const getPipelineCounts = data => {
  const category = simpleGroupBy(data, 'activityType');
  const subcategory = simpleGroupBy(data, 'statusAbbr');

  const candidates: ICandidateCounts = {
    candidateCount: getCandidatesCount(data) || 0,
    inSubmission: getCandidatesCount(category[placementStatus.inSubmission]) || 0,
    waitingForClearance: getCandidatesCount(subcategory[placementSubStatuses.wfc]) || 0,
    requestFileOut: getCandidatesCount(subcategory[placementSubStatuses.rfo]) || 0,
    sent: getCandidatesCount(subcategory[placementSubStatuses.sent]) || 0,
    interviewed: getCandidatesCount(subcategory[placementSubStatuses.intrwd]) || 0,
    offer: getCandidatesCount(category[placementStatus.offer]) || 0,
    offerByFacility: getCandidatesCount(subcategory[placementSubStatuses.obf]) || 0,
    offerBookedByTraveler: getCandidatesCount(subcategory[placementSubStatuses.obbt]) || 0,
    facilityConfirms: getCandidatesCount(subcategory[placementSubStatuses.fc]) || 0,
    travelerAccepts: getCandidatesCount(subcategory[placementSubStatuses.ta]) || 0,
    bookedCategory: getCandidatesCount(category[placementStatus.booked]) || 0,
    bookedSubCategory: getCandidatesCount(subcategory[placementSubStatuses.booked]) || 0,
    bookingApproved: getCandidatesCount(subcategory[placementSubStatuses.ba]) || 0,
    waitingGoodToGo: getCandidatesCount(subcategory[placementSubStatuses.wgtg]) || 0,
    postponementWarning: getCandidatesCount(subcategory[placementSubStatuses.ppw]) || 0,
    goodToGo: getCandidatesCount(subcategory[placementSubStatuses.gtg]) || 0,
    onAssignment: getCandidatesCount(category[placementStatus.onAssignment]) || 0,
  };

  const placements: IPlacementCounts = {
    inSubmission: category[placementStatus.inSubmission]?.length || 0,
    waitingForClearance: subcategory[placementSubStatuses.wfc]?.length || 0,
    requestFileOut: subcategory[placementSubStatuses.rfo]?.length || 0,
    sent: subcategory[placementSubStatuses.sent]?.length || 0,
    interviewed: subcategory[placementSubStatuses.intrwd]?.length || 0,
    offer: category[placementStatus.offer]?.length || 0,
    offerByFacility: subcategory[placementSubStatuses.obf]?.length || 0,
    offerBookedByTraveler: subcategory[placementSubStatuses.obbt]?.length || 0,
    facilityConfirms: subcategory[placementSubStatuses.fc]?.length || 0,
    travelerAccepts: subcategory[placementSubStatuses.ta]?.length || 0,
    bookedCategory: category[placementStatus.booked]?.length || 0,
    bookedSubCategory: subcategory[placementSubStatuses.booked]?.length || 0,
    bookingApproved: subcategory[placementSubStatuses.ba]?.length || 0,
    waitingGoodToGo: subcategory[placementSubStatuses.wgtg]?.length || 0,
    postponementWarning: subcategory[placementSubStatuses.ppw]?.length || 0,
    goodToGo: subcategory[placementSubStatuses.gtg]?.length || 0,
    onAssignment: category[placementStatus.onAssignment]?.length || 0,
  };
  return { candidates, placements };
};

export const createFilterChips = (filter, selectedChips, t) =>
  Object.fromEntries(
    Object.entries(filter).map(field => [
      field[0],
      field[0] === recPlacementsFilterName.placementRecruiter
        ? field[1]?.map(data => {
            return {
              label: `${t('home.recruiterWorkDesk.placementFilter.placementRecruiter')} : ${data?.name}`,
              isSelected: !!selectedChips?.placementRecruiter?.find(recruiter => recruiter.id === data?.id) || false,
              enableDelete: true,
              value: data,
            };
          })
        : // Temporary fix for Advanced filter chips
        [
            recPlacementsFilterName.nonContactedDate.toString(),
            recPlacementsFilterName.lastApplicationDateRange.toString(),
            recPlacementsFilterName.availableToStartDateRange.toString(),
            recPlacementsFilterName.callBackDateRange.toString(),
          ].includes(field[0])
        ? field[1]
          ? {
              label: `${t(`home.recruiterWorkDesk.placementFilter.${field[0]}`)} : ${
                (field[1] as any).selectChoice ||
                (field[0].includes(recPlacementsFilterName.nonContactedDate)
                  ? GetMomentOfDate((field[1] as any).date).format('MM/DD/YYYY')
                  : getDateRangeLabel(field[1] as any)) ||
                (field[0].includes(recPlacementsFilterName.activeCues)
                  ? GetMomentOfDate((field[1] as any).date).format('MM/DD/YYYY')
                  : getDateRangeLabel(field[1] as any))
              }`,
              enableDelete: true,
            }
          : null
        : generateFieldChips(
            field[1],
            selectedChips ? selectedChips[field[0]] : undefined,
            getPrefixLabel(t)(field[0]),
            false,
          ),
    ]),
  ) as unknown as IPlacementFilterChips;

const getPrefixLabel = t => key =>
  customSwitch({
    [recPlacementsFilterName.availabilityStartDateRange]: t(
      `home.recruiterWorkDesk.placementFilter.${recPlacementsFilterName.availabilityStartDateRange}`,
    ),
    [recPlacementsFilterName.startDateRange]: t(
      `home.recruiterWorkDesk.placementFilter.${recPlacementsFilterName.startDateRange}`,
    ),
    [recPlacementsFilterName.endDateRange]: t(
      `home.recruiterWorkDesk.placementFilter.${recPlacementsFilterName.endDateRange}`,
    ),
    [recPlacementsFilterName.nonContactedDate]: t(
      `home.recruiterWorkDesk.placementFilter.${recPlacementsFilterName.nonContactedDate}`,
    ),
    [recPlacementsFilterName.lastApplicationDateRange]: t(
      `home.recruiterWorkDesk.placementFilter.${recPlacementsFilterName.lastApplicationDateRange}`,
    ),
    [recPlacementsFilterName.availableToStartDateRange]: t(
      `home.recruiterWorkDesk.placementFilter.${recPlacementsFilterName.availableToStartDateRange}`,
    ),
    [recPlacementsFilterName.callBackDateRange]: t(
      `home.recruiterWorkDesk.placementFilter.${recPlacementsFilterName.callBackDateRange}`,
    ),
    [recPlacementsFilterName.activeCues]: t(
      `home.recruiterWorkDesk.placementFilter.${recPlacementsFilterName.activeCues}`,
    ),
    ['availableStartDate']: t('Available To Start Date'),
    ['billrate']: t('Bill Rate $'),
    [OrderMatchFlterName.guaranteedHours]: t('Guaranteed Hours'),
    [OrderMatchFlterName.expectedHours]: t('Expected hours'),
    [OrderMatchFlterName.orderEndDateRange]: t('Order End Date Range'),
    [OrderMatchFlterName.orderAgeDateRange]: t('Order Age Date Range'),
    [OrderMatchFlterName.weeklyGrossPay]: t('Gross Pay Range ($)'),
    [OrderMatchFlterName.startDateRange]: t('Order Start Date Range'),
    [OrderMatchFlterName.orderIds]: t('OID'),
    [OrderMatchFlterName.region]: t('Region'),
    [recPlacementsFilterName.arbitrationStatus]: t('home.recruiterWorkDesk.placementFilter.arbitrationAgreement'),
    [FacilityCommunicationHistoryFiltersrName.date]: t('facility.facilityCommunicationHistory.date'),
  })(null)(key);

const RemoveAllOption = filter => filter?.filter(x => x.value !== 'All') ?? [];

export const getFilteredData = (filter, sourceData, chipSelected = {}) => {
  const data = sourceData.reduce((resultArray, item) => {
    if (
      isDataIncluded(
        item[recPlacementsGridKeys.placementRecruiter],
        chipSelected[recPlacementsFilterName.placementRecruiter] ||
          RemoveAllOption(filter[recPlacementsFilterName.placementRecruiter]),
      ) &&
      isItemIncluded(
        item[recPlacementsGridKeys.candidate],
        chipSelected[recPlacementsFilterName.candidateName] ||
          RemoveAllOption(filter[recPlacementsFilterName.candidateName]),
      ) &&
      isSubstringIncluded(
        item[recPlacementsGridKeys.skillSet].split('-')[0],
        chipSelected[recPlacementsFilterName.discipline] || RemoveAllOption(filter[recPlacementsFilterName.discipline]),
      ) &&
      isSubstringIncluded(
        item[recPlacementsGridKeys.skillSet].split('-')[1],
        chipSelected[recPlacementsFilterName.specialty] || RemoveAllOption(filter[recPlacementsFilterName.specialty]),
      ) &&
      (filter[recPlacementsFilterName.endDateRange]
        ? item[recPlacementsGridKeys.endDate]
          ? itemIsInRange(
              item[recPlacementsGridKeys.endDate],
              RemoveAllOption(filter[recPlacementsFilterName.endDateRange]),
            )
          : false
        : true) &&
      (filter[recPlacementsFilterName.availabilityStartDateRange]
        ? item[recPlacementsGridKeys.availabilityStartDate]
          ? itemIsInRange(
              item[recPlacementsGridKeys.availabilityStartDate],
              filter[recPlacementsFilterName.availabilityStartDateRange],
            )
          : false
        : true) &&
      (filter[recPlacementsFilterName.activeCues]
        ? item[recPlacementsGridKeys.cueNoteAttributes]?.date
          ? itemIsInRange(
              item[recPlacementsGridKeys.cueNoteAttributes]?.date,
              filter[recPlacementsFilterName.activeCues],
            )
          : false
        : true) &&
      (filter[recPlacementsFilterName.activeCues] && filter[recPlacementsFilterName.placementRecruiter]
        ? isDateIncluded(
            item[recPlacementsGridKeys.cueNoteAttributes],
            filter[recPlacementsFilterName.placementRecruiter],
          )
        : true) &&
      (filter[recPlacementsFilterName.startDateRange]
        ? item[recPlacementsGridKeys.startDate]
          ? itemIsInRange(item[recPlacementsGridKeys.startDate], filter[recPlacementsFilterName.startDateRange])
          : false
        : true) &&
      (filter[recPlacementsFilterName.nonContactedDate]
        ? item[recPlacementsGridKeys.notContactedSinceDate]
          ? itemIsTillDate(
              item[recPlacementsGridKeys.notContactedSinceDate],
              filter[recPlacementsFilterName.nonContactedDate].date,
            )
          : false
        : true) &&
      (filter[recPlacementsFilterName.lastApplicationDateRange]
        ? item[recPlacementsGridKeys.lastApplicationDate]
          ? itemIsInRange(
              item[recPlacementsGridKeys.lastApplicationDate],
              filter[recPlacementsFilterName.lastApplicationDateRange],
            )
          : false
        : true) &&
      (filter[recPlacementsFilterName.availableToStartDateRange]
        ? item[recPlacementsGridKeys.availableToStartDate]
          ? itemIsInRange(
              item[recPlacementsGridKeys.availableToStartDate],
              filter[recPlacementsFilterName.availableToStartDateRange],
            )
          : false
        : true) &&
      (filter[recPlacementsFilterName.callBackDateRange]
        ? item[recPlacementsGridKeys.callBackDate]
          ? itemIsInRange(item[recPlacementsGridKeys.callBackDate], filter[recPlacementsFilterName.callBackDateRange])
          : false
        : true) &&
      isItemIncluded(
        item[recPlacementsGridKeys.facilityName],
        chipSelected[recPlacementsFilterName.facilityName] ||
          RemoveAllOption(filter[recPlacementsFilterName.facilityName]),
      ) &&
      isItemIncluded(
        item[recPlacementsGridKeys.facilityCity],
        chipSelected[recPlacementsFilterName.city] || RemoveAllOption(filter[recPlacementsFilterName.city]),
      ) &&
      isItemIncluded(
        item[recPlacementsGridKeys.facilityState],
        chipSelected[recPlacementsFilterName.state] || RemoveAllOption(filter[recPlacementsFilterName.state]),
      ) &&
      (filter[recPlacementsFilterName.arbitrationStatus]
        ? item[recPlacementsGridKeys.arbitrationStatus]
          ? isItemIncluded(
              item[recPlacementsGridKeys.arbitrationStatus],
              chipSelected[recPlacementsFilterName.arbitrationStatus] ||
                filter[recPlacementsFilterName.arbitrationStatus],
            )
          : false
        : true)
    )
      resultArray.push(item);
    return resultArray;
  }, []);

  return {
    countObject: getPipelineCounts(data),
    flattenedData: data,
  };
};

//////Advanced FilterChips//////////////
export const createAdvancedFilterChips = (fieldName, newValue, chips, t, dispatch) => {
  dispatch(
    recruiterPlacementActions.setFilterChips({
      ...chips,
      [fieldName]: {
        label: `${t(`recruiterWorkDesk.placementFilter.${fieldName}`)}: ${
          newValue.selectChoice ||
          (fieldName.includes(recPlacementsFilterName.nonContactedDate)
            ? GetMomentOfDate(newValue.date).format('MM/DD/YYYY')
            : getDateRangeLabel(newValue))
        }`,
        enableDelete: true,
      },
    }),
  );
};

////////Last Contacted//////////
export const getLastContactedDate = customSwitch({
  0: getDateValue(-1),
  1: getDateValue(-2),
  2: getDateValue(-7),
  3: getDateValue(-20),
  4: getDateValue(-30),
  5: getDateValue(-60),
})(undefined);

////////Last Application Date/////////
export const getLastApplicationDateRange = customSwitch({
  0: () => ({ startDate: getDateValue(0), endDate: getDateValue(0, true) }),
  1: () => ({
    startDate: getAmnStartOfWeek(),
    endDate: getAmnEndOfWeek(),
  }),
  2: () => ({ startDate: getDateValue(-30), endDate: getDateValue(0, true) }),
  3: () => ({ startDate: getDateValue(-60), endDate: getDateValue(0, true) }),
})(undefined);

///////Available To Start///////
export const getAvailableToStartDateRange = customSwitch({
  0: () => ({
    startDate: getAmnStartOfWeek(),
    endDate: getAmnEndOfWeek(),
  }),
  1: () => ({
    startDate: getFirstOfMonth(false),
    endDate: getLastOfMonth(false),
  }),
  2: () => ({
    startDate: getFirstOfMonth(true),
    endDate: getLastOfMonth(true),
  }),
})(undefined);

///////Call Back Date///////
export const getCallBackDateRange = customSwitch({
  0: () => ({ startDate: getDateValue(0), endDate: getDateValue(0, true) }),
  1: () => ({
    startDate: getAmnStartOfWeek(),
    endDate: getAmnEndOfWeek(),
  }),
  2: () => ({ startDate: getDateValue(-30), endDate: getDateValue(0, true) }),
  3: () => ({ startDate: getDateValue(-60), endDate: getDateValue(0, true) }),
})(undefined);

/////////Tiles helper///////////

export const getTileCounts = data => {
  const nonContactedDate30 = getCandidatesCount(
    data.filter(item => itemIsTillDate(item.notContactedSinceDate, getLastContactedDate(4))),
  );
  const nonContactedDate20 = getCandidatesCount(
    data.filter(item => itemIsTillDate(item.notContactedSinceDate, getLastContactedDate(3))),
  );
  const callBackToday = getCandidatesCount(
    data.filter(item => itemIsInRange(item.callBackDate, getCallBackDateRange(0))),
  );
  const callBackThisWeek = getCandidatesCount(
    data.filter(item => itemIsInRange(item.callBackDate, getCallBackDateRange(1))),
  );
  const lastApplicationToday = getCandidatesCount(
    data.filter(item => itemIsInRange(item.lastApplicationDate, getLastApplicationDateRange(0))),
  );
  const lastApplicationThisWeek = getCandidatesCount(
    data.filter(item => itemIsInRange(item.lastApplicationDate, getLastApplicationDateRange(1))),
  );
  const availableToStartThisWeek = getCandidatesCount(
    data.filter(item => itemIsInRange(item.availableToStartDate, getAvailableToStartDateRange(0))),
  );
  const availableToStartNextMonth = getCandidatesCount(
    data.filter(item => itemIsInRange(item.availableToStartDate, getAvailableToStartDateRange(2))),
  );
  return {
    nonContactedDate30,
    nonContactedDate20,
    callBackToday,
    callBackThisWeek,
    lastApplicationToday,
    lastApplicationThisWeek,
    availableToStartThisWeek,
    availableToStartNextMonth,
  };
};

/////////// saga helper ////////////////
export const getRecruiterList = (recruiters, userInfo) =>
  [
    {
      id: userInfo.employeeId,
      name: Concatenate([userInfo.firstName, userInfo.lastName], ' '),
      value: userInfo.employeeId,
      object: {
        userId: userInfo.employeeId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      },
    },
    ...recruiters.reduce(
      (list, recruiter) =>
        recruiter.userId !== userInfo.employeeId
          ? [
              ...list,
              {
                id: recruiter.userId,
                name: Concatenate([recruiter.firstName, recruiter.lastName], ' '),
                value: recruiter.userId,
                object: recruiter,
              },
            ]
          : list,
      [],
    ),
  ] as ITypeAheadOption[];

const filterChoiceWithNewOptions = (
  choices: ISelection[],
  newOpts: ISelection[],
  fieldName: string,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void,
) => {
  const newChoices = choices.reduce((resultArray, choice) => {
    if (newOpts.some(opt => opt.value === choice.value)) resultArray.push(choice);
    return resultArray;
  }, [] as ISelection[]);

  setValue(fieldName, newChoices, { shouldDirty: true });
  return newChoices;
};

export const filterUserChoices = (
  filterSpecs: IFilterSpecs,
  values: IPlacementFilter,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void,
) => {
  const candidateName = filterChoiceWithNewOptions(
    values.candidateName,
    filterSpecs.candidateNameOpts,
    'candidateName',
    setValue,
  );
  const discipline = filterChoiceWithNewOptions(values.discipline, filterSpecs.disciplineOpts, 'discipline', setValue);
  const specialty = filterChoiceWithNewOptions(values.specialty, filterSpecs.specialtyOpts, 'specialty', setValue);
  const facilityName = filterChoiceWithNewOptions(
    values.facilityName,
    filterSpecs.facilityNameOpts,
    'facilityName',
    setValue,
  );
  const city = filterChoiceWithNewOptions(values.city, filterSpecs.cityOpts, 'city', setValue);
  const state = filterChoiceWithNewOptions(values.state, filterSpecs.stateOpts, 'state', setValue);
  const arbitrationStatus = filterChoiceWithNewOptions(
    values.arbitrationStatus || [],
    filterSpecs.arbitrationStatusOpts,
    'arbitrationStatus',
    setValue,
  );

  values.availabilityStartDateRange &&
    setValue(recPlacementsFilterName.availabilityStartDateRange, values.availabilityStartDateRange, {
      shouldDirty: true,
    });
  values.startDateRange &&
    setValue(recPlacementsFilterName.startDateRange, values.startDateRange, { shouldDirty: true });
  values.endDateRange && setValue(recPlacementsFilterName.endDateRange, values.endDateRange, { shouldDirty: true });
  values.nonContactedDate &&
    setValue(recPlacementsFilterName.nonContactedDate, values.nonContactedDate, { shouldDirty: true });
  values.lastApplicationDateRange &&
    setValue(recPlacementsFilterName.lastApplicationDateRange, values.lastApplicationDateRange, { shouldDirty: true });
  values.availableToStartDateRange &&
    setValue(recPlacementsFilterName.availableToStartDateRange, values.availableToStartDateRange, {
      shouldDirty: true,
    });
  values.activeCues &&
    setValue(recPlacementsFilterName.activeCues, values.activeCues, {
      shouldDirty: true,
    });
  values.callBackDateRange &&
    setValue(recPlacementsFilterName.callBackDateRange, values.callBackDateRange, { shouldDirty: true });
  return { ...values, candidateName, discipline, specialty, facilityName, city, state, arbitrationStatus };
};
