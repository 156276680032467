import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './FacilityUnit.redux';

const selectDomain = (state: RootState) => state?.facilityUnits || initialState;

export const selectUnitsGridData = createSelector([selectDomain], data =>
  data.filteredData?.length > 0 ? data.filteredData : data.flattenedData,
);

export const selectUnitsSourceData = createSelector([selectDomain], data => data.flattenedData);

export const selectSearchResults = createSelector([selectDomain], data => data.searchResults);

export const selectUnitsLoading = createSelector([selectDomain], credentialingState => credentialingState.loading);

export const selectFilterSpecs = createSelector([selectDomain], data => data.filterSpecs);
export const selectFilterChipSelected = createSelector([selectDomain], data => data.filterChipSelected);
export const selectUnitChips = createSelector([selectDomain], data => data.chips);
export const selectUnitsData = createSelector([selectDomain], data => data.unitsSourceData);
export const selectIsUnitSelected = createSelector([selectDomain], data => data.isUnitSelected);
export const selectUnit = createSelector([selectDomain], data => data.selectedUnit);
export const selectCertificatesFullName = createSelector([selectDomain], data => data.certificatesFullName);
export const selectEditMode = createSelector([selectDomain], data => data.editMode);
export const selectIsStandardize = createSelector([selectDomain], data => data.isStandardize);
export const selectValidationAlert = createSelector([selectDomain], data => data.validationAlert);
export const selectUnitClone = createSelector([selectDomain], data => data.unitsClone);
export const selectNavToEdit = createSelector([selectDomain], data => data.navToEdit);
export const selectSkillsSetsForUnits = createSelector([selectDomain], data => data.skillsSets);
export const selectTransformedIssuedBy = createSelector([selectDomain], data => data.transformedIssuedBy);
export const selectCreatedUnitFromOrder = createSelector([selectDomain], data => data.isCreateNewUnitFromOrder);
export const selectCreatedUnit = createSelector([selectDomain], data => data.isCreateNewUnit);
export const selectIsUnitFormDirty = createSelector([selectDomain], data => data.isUnitFormDirty);
export const selectOrdersForUnit = createSelector([selectDomain], data => data.ordersUnit);
export const selectSubCert = createSelector([selectDomain], data => data.selectedSubCert);
export const selectLicenseData = createSelector([selectDomain], data => data.licenseData);
export const selectCompactStatus = createSelector([selectDomain], data => data.compactStatus);
export const selectCancelPopupStatus = createSelector([selectDomain], results => results?.openCancelModel);
export const selectUnitSaveNameError = createSelector([selectDomain], data => data.inValidUnitName);
