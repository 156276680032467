import { Tooltip, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { JabberExtensionCode, unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import { UserDeviceProps } from 'app/models/Notification/Notification';
import { selectUser } from 'oidc/user.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Zoom } from 'react-toastify';
import styledComponent from 'styled-components';
import { ToasterContent } from '../../Common/ToasterContent';
import { ToasterHeader } from '../../Common/ToasterHeader';
import { ChannelType, UseType } from '../../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import {
  notificationSelection,
  selectVoiceAction,
  selectVoiceDisposition,
  selectVoiceLoader,
} from 'store/redux-store/notification/notification.selector';
import { NotificationContext } from '../../NotificationWrapper';
import { formatVoiceData, getDispositionPayload, getInitialDeviceLine, RecordName } from './Helper';
import DeviceSelection from './DeviceSelection';
import VoiceDisposition from './VoiceDisposition';
import { theme } from 'styles/global-styles';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

const useStyles = makeStyles()(theme => ({
  tooltipBackground: {
    backgroundColor: theme.palette.framework.system.white,
    color: theme.palette.framework.system.doveGray,
    border: `1px solid ${theme.palette.framework.system.doveGray}`,
    fontSize: '12px',
  },
  modalHeaderSubtitle: {
    textOverflow: 'ellipsis',
    color: theme.palette.framework.system.charcoal,
    fontSize: '1.1rem',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
    letterSpacing: '0px',
    font: 'normal normal medium 14px/16px Roboto',
    opacity: '1',
  },
}));

const SubHeader = styledComponent(Typography)`
  color: ${theme.palette.framework.system.charcoal};
  font-weight: 600;
  font-size: 1rem;
  opacity: 1;
  text-align: left;
  font: normal normal medium 14px/16px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  @media (max-width: 1650px) {
    font-size: 0.7rem;
  }
`;

export const Voice = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const globalData = useSelector(notificationSelection);
  const { handleSubmitResponse } = useContext(NotificationContext);
  const data = globalData?.voice?.data;
  const userDevice = useSelector(selectUserPreference);
  const user = useSelector(selectUser);
  const voiceAction = useSelector(selectVoiceAction);
  const voiceDisposition = useSelector(selectVoiceDisposition);
  const loader = useSelector(selectVoiceLoader);
  const userDevicePreference = useSelector(selectUserPreference);

  const [notes, setNotes] = useState<string>('');
  const [addToSummary, setAddToSummary] = useState<boolean>(false);
  const [summaryCheckboxEnabled, setSummaryCheckboxEnabled] = useState<boolean>(false);
  const [userDeviceLine, setUserDeviceLine] = useState<UserDeviceProps>(getInitialDeviceLine(userDevicePreference));

  useEffect(() => {
    const voiceData = JSON.parse(JSON.stringify(data));
    dispatch(notificationDataActions.setVoiceLoader(true));
    dispatch(notificationDataActions.setVoiceDisposition(getDispositionPayload(data)));
    if (globalData.voice?.showDisposition) {
      dispatch(notificationDataActions.setVoiceLoader(false));
      dispatch(notificationDataActions.setVoiceAction({ ...voiceAction, showDisposition: true }));
    } else if (!isNullOrUndefined(userDevicePreference?.existing?.device || userDevicePreference?.existing?.line)) {
      dispatch(notificationDataActions.setVoiceLoader(false));
      HandleInitiateCall();
      dispatch(
        notificationDataActions.setVoiceAction({
          ...voiceAction,
          openDeviceSelection: false,
          showDisposition: !voiceData?.isInternal,
        }),
      );
      dispatch(
        notificationDataActions.setVoiceInteraction({
          open: true,
          minimized: false,
          showDisposition: !voiceData?.isInternal,
          displayAddToSummary: globalData?.voice?.displayAddToSummary ?? false,
        }),
      );
    } else {
      dispatch(notificationDataActions.setVoiceLoader(false));
      setUserDeviceLine(prevState => ({
        ...prevState,
        deviceList: userDevicePreference?.deviceList,
        deviceLine: userDevicePreference?.deviceLine,
        isDefaultDeviceAndLine: false,
      }));
      dispatch(notificationDataActions.setVoiceAction({ ...voiceAction, openDeviceSelection: true }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HandleInitiateCall = () => {
    if (userDeviceLine?.isDefaultDeviceAndLine) {
      const userPreference = {
        ...userDevice,
        current: {
          device: userDevicePreference?.current?.device,
          line: userDevicePreference?.current?.line,
        },
        existing: {
          device: userDevicePreference?.current?.device,
          line: userDevicePreference?.current?.line,
        },
        default: true,
      };
      dispatch(userDevicePreferenceActions.setUserPreference(userPreference));
      const preferredUser = {
        userId: user?.userInfo?.employeeId?.toString(),
        deviceName: userDevice?.current?.device,
        lineNumber: userDevice?.current?.line,
      };
      const preferenceData = {
        id: 'DevicePreference',
        value: preferredUser,
      };
      dispatch(userDevicePreferenceActions.saveUserDevicePreferenceAction(preferenceData));
      // saveUserDevicePreference(preferredUser)
      handleSubmitResponse(true, 'success', t('notification.voice.updatedPreferenceSuccess'));
    }
    const voiceData = JSON.parse(JSON.stringify(data));
    if (data && data?.tos.find(Boolean)?.phoneNumber) {
      voiceData.tos.find(Boolean).phoneNumber =
        JabberExtensionCode + unformatPhoneNumber(voiceData?.tos.find(Boolean)?.phoneNumber);
    }
    voiceData.sender.deviceName = userDevicePreference?.current?.device;
    voiceData.sender.lineNumber = userDevicePreference?.current?.line;

    dispatch(notificationDataActions.initiateCall({ data: voiceData }));
  };

  const saveManualCallLog = () => {
    const voiceData = JSON.parse(JSON.stringify(voiceDisposition));
    if (data && data?.tos.find(Boolean)?.phoneNumber) {
      voiceData.tos.find(Boolean).phoneNumber =
        JabberExtensionCode + unformatPhoneNumber(voiceData?.tos.find(Boolean)?.phoneNumber);
    }
    voiceData.initiatedOn = voiceData.date.substring(0, 11) + voiceData.time.substring(11, voiceData.time?.length);
    const voiceDataRefactored = { ...voiceData, updateCandidateSummary: addToSummary };

    if (!data?.tos.find(Boolean)?.contactId) {
      voiceDataRefactored.associatedRecords[0].value = null;
      voiceDataRefactored.associatedRecords[1].value = null;
    }

    dispatch(notificationDataActions.saveCallLog({ callLog: formatVoiceData(voiceDataRefactored), isManualLog: true }));
  };

  const handleDisposition = (value, key) => {
    const dispositionData = JSON.parse(JSON.stringify(voiceDisposition));
    dispositionData[key] = value;
    dispatch(notificationDataActions.setVoiceDisposition({ ...voiceDisposition, [key]: value }));
    dispatch(
      notificationDataActions.setVoiceData({
        data: dispositionData,
        open: true,
        showDisposition: true,
        displayAddToSummary: globalData?.voice?.displayAddToSummary ?? false,
      }),
    );
  };

  const handleMinimize = () => {
    const toasterId = data?.channel;
    dispatch(notificationDataActions.setVoiceAction({ ...voiceAction, minimize: true, showDisposition: false }));
    dispatch(
      notificationDataActions.setVoiceInteraction({
        open: false,
        minimized: true,
        showDisposition: false,
        displayAddToSummary: globalData?.voice?.displayAddToSummary ?? false,
      }),
    );
    dispatch(notificationDataActions.incrementActiveToaster());
    dispatch(notificationDataActions.setSnackBarData(null));
    toast(
      <ToasterContent
        channel={ChannelType.voice}
        id={toasterId}
        info={globalData?.voice?.data}
        header={
          data?.tos.find(Boolean)?.contactId
            ? ToasterHeader(data?.tos.find(Boolean)?.name, data?.tos.find(Boolean)?.contactId)
            : `${data?.tos.find(Boolean)?.phoneNumber}`
        }
        subheader={
          data.useType === UseType.ClientContacts
            ? getTooltipSubHeader(8)
            : `${data?.tos.find(Boolean)?.phoneNumber} | ${t('notification.voice.manualLog.contactId')} ${
                data?.tos.find(Boolean)?.contactId
              }`
        }
      />,
      {
        toastId: toasterId,
        containerId: 'channel',
        position: 'bottom-right',
        closeButton: false,
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        transition: Zoom,
      },
    );
  };

  const getTooltipSubHeader = (length: number) => {
    const title = data.associatedRecords?.filter(
      value => value.name === RecordName.facility || RecordName.unit || RecordName.placement,
    )[0];
    return title?.value?.length > length ? (
      <Tooltip arrow classes={{ tooltip: classes.tooltipBackground }} title={title.value}>
        <SubHeader variant="subtitle1" className={classes.modalHeaderSubtitle}>
          {`${data?.tos.find(Boolean)?.phoneNumber} | ${title.value.substring(0, length) + '...'}`}
        </SubHeader>
      </Tooltip>
    ) : (
      <SubHeader variant="subtitle1" className={classes.modalHeaderSubtitle}>
        {`${data?.tos.find(Boolean)?.phoneNumber} | ${title.value}`}
      </SubHeader>
    );
  };

  return (
    <React.Fragment>
      {loader && <CustomBackdrop open />}
      {voiceAction?.openDeviceSelection && (
        <DeviceSelection
          HandleInitiateCall={HandleInitiateCall}
          userDeviceLine={userDeviceLine}
          setUserDeviceLine={setUserDeviceLine}
        />
      )}
      {voiceAction?.showDisposition && (
        <VoiceDisposition
          notes={notes}
          addToSummary={addToSummary}
          summaryCheckboxEnabled={summaryCheckboxEnabled}
          setNotes={setNotes}
          setAddToSummary={setAddToSummary}
          handleDisposition={handleDisposition}
          saveManualCallLog={saveManualCallLog}
          handleMinimize={handleMinimize}
          setSummaryCheckboxEnabled={setSummaryCheckboxEnabled}
        />
      )}
    </React.Fragment>
  );
};
