import { Grid, Theme } from 'amn-ui-core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { getAuditCategory, getAuditSubCategory } from 'app/services/SharedServices/SharedServices';
import { FormInputCheckbox } from '../../PersonalInfoTab/Form/Controls/FormInputCheckbox';
import { ControlledTypeAhead } from '../../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import {
  AuditActions,
  AuditType,
  CategoryTypes,
  PrimaryResponsibleValues,
  StatusTypes,
  auditTypeViewEditPermission,
} from './Helpers/AuditAddHelpers';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'oidc/user.selectors';
import { CustomFormInputText } from './Helpers/CustomFormInputText';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '20px 2px 20px 20px',
    marginBottom: '5px',
    flexWrap: 'nowrap',
    overflowX: 'hidden',
    maxWidth: '960px',
    minHeight: 'calc(70vh - 10.5rem)',
  },
  chip: {
    margin: '3px',
    color: '#333333',
    border: '1px solid #CCCCCC',
    fontSize: '14px',
    background: '#FFFFFF',
    '&:hover': {
      color: 'white !important',
      background: '#009AD9',
      '& .MuiChip-deleteIcon': {
        color: 'white !important',
        display: 'block',
      },
    },
  },
  gridItem: {
    maxHeight: '80px',
  },
  addButton: {
    backgroundColor: '#009AD9',
    '&:hover': {
      backgroundColor: '#009AD9',
    },
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff',
    marginTop: '2px',
  },
  disabledButton: {
    backgroundColor: '#8E9195 !important',
    '&:hover': {
      backgroundColor: '#8E9195 !important',
    },
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff !important',
    cursor: 'not-allowed',
    marginTop: '2px',
  },
  disabledInput: {
    backgroundColor: 'rgba(0,0,0, 0.02) !important',
  },
  checkboxLabelStyle: {
    textWrap: 'nowrap',
  },
}));

export const AuditAddContent = (props: {
  setFormValues?: (value: any) => void;
  existingFormValues?: any;
  addMode: boolean;
  disabled: boolean;
}) => {
  const { setFormValues, existingFormValues, addMode, disabled } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { errors, getValues, setValue, register, control } = useFormContext();
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<ITypeAheadOption[]>([]);
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState<any>({
    category: false,
    subCategory: false,
  });
  const lookupSelection = useSelector(lookupSelector);

  useEffect(() => {
    Object.entries(existingFormValues)?.forEach(([key, value]) => {
      setValue(key, value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingFormValues]);

  const checkForRequirements = (auditSelected: string, categorySelected: string | null) => {
    if (
      auditSelected === AuditType.cancellation ||
      auditSelected === AuditType.experienceTouchpoint ||
      auditSelected === AuditType.noncancellationIncident
    ) {
      setFormValues({
        ...existingFormValues,
        isPlacementRequired: true,
        isSubCategoryRequired: true,
      });
    } else if (
      auditSelected === AuditType.clinicianExperienceSurveyResponse ||
      categorySelected.toLocaleLowerCase() === CategoryTypes.workplace_accommodation ||
      (auditSelected === AuditType.dns &&
        categorySelected.toLocaleLowerCase() !== CategoryTypes.ncs &&
        categorySelected.toLocaleLowerCase() !== CategoryTypes.integration)
    ) {
      setFormValues({
        ...existingFormValues,
        isPlacementRequired: false,
        isSubCategoryRequired: true,
      });
    } else {
      setFormValues({
        ...existingFormValues,
        isPlacementRequired: false,
        isSubCategoryRequired: false,
      });
    }
  };

  const setDefaults = (auditSelected: any) => {
    let formValuesTemp = existingFormValues;
    formValuesTemp = {
      ...formValuesTemp,
      pType: auditSelected,
      pCategory: null,
      pSubCategory: null,
      pDescription: null,
      isSubCategoryRequired: false,
    };
    setValue('pCategory', null);
    setValue('pSubCategory', null);
    setValue('pDescription', null);
    if (auditSelected.label === AuditType.dns) {
      formValuesTemp = {
        ...formValuesTemp,
        pStatus:
          dropdownOptionFormat(lookupSelection?.auditStatus)?.find(item => item.label === StatusTypes.ongoing) || null,
        pAction:
          dropdownOptionFormat(lookupSelection?.auditAction)?.find(
            item => item.label === AuditActions.need_management_review,
          ) || null,
        pPrimaryResponsibleParty:
          dropdownOptionFormat(lookupSelection?.auditResponsible)?.find(
            item => item.label === PrimaryResponsibleValues.hospital_account_manager,
          ) || null,
        pGroup: 'TBD',
        pHoldFileOut: false,
      };
      setValue(
        'pStatus',
        dropdownOptionFormat(lookupSelection?.auditStatus)?.find(item => item.label === StatusTypes.ongoing) || null,
      );
      setValue(
        'pAction',
        dropdownOptionFormat(lookupSelection?.auditAction)?.find(
          item => item.label === AuditActions.need_management_review,
        ) || null,
      );
      setValue(
        'pPrimaryResponsibleParty',
        dropdownOptionFormat(lookupSelection?.auditResponsible)?.find(
          item => item.label === PrimaryResponsibleValues.hospital_account_manager,
        ) || null,
      );
      setValue('pGroup', 'TBD');
    } else {
      formValuesTemp = {
        ...formValuesTemp,
        pStatus: null,
        pAction: null,
        pPrimaryResponsibleParty: null,
        pGroup: null,
      };
      setValue('pStatus', null);
      setValue('pAction', null);
      setValue('pPrimaryResponsibleParty', null);
      setValue('pGroup', null);
    }
    if (
      auditSelected.label === AuditType.experienceTouchpoint ||
      auditSelected.label === AuditType.applicationReferencing ||
      (existingFormValues.pType !== null && auditSelected.label === AuditType.dns)
    ) {
      formValuesTemp = {
        ...formValuesTemp,
        pHoldFileOut: false,
      };
      setValue('pHoldFileOut', false);
    }
    setFormValues(formValuesTemp);
  };

  const getCategoryDropdown = async () => {
    if (existingFormValues.pType) {
      const auditTypeId = parseInt(existingFormValues.pType.object?.ID);
      await getAuditCategory(auditTypeId)
        .then(response => {
          if (response.status === 200) {
            setCategoryOptions(response.data);
            setLoading({
              ...loading,
              category: false,
            });
          }
        })
        .catch(e => {
          setCategoryOptions([]);
          setLoading({
            ...loading,
            category: false,
          });
        });
    }
  };

  const getSubCategoryDropdown = async () => {
    if (existingFormValues.pCategory) {
      checkForRequirements(existingFormValues.pType?.label, existingFormValues.pCategory.label);
      const auditCategoryId = parseInt(existingFormValues.pCategory.object?.ID);
      await getAuditSubCategory(auditCategoryId)
        .then(response => {
          if (response.status === 200) {
            const options = dropdownOptionFormat(response.data);
            setSubCategoryOptions(options);
            setLoading({
              ...loading,
              subCategory: false,
            });
          }
        })
        .catch(e => {
          setSubCategoryOptions([]);
          setLoading({
            ...loading,
            subCategory: false,
          });
        });
    }
  };

  useEffect(() => {
    if (existingFormValues.pType) {
      setLoading({
        ...loading,
        category: true,
      });
      getCategoryDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingFormValues.pType]);

  useEffect(() => {
    if (existingFormValues.pCategory) {
      setLoading({
        ...loading,
        subCategory: true,
      });
      getSubCategoryDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingFormValues.pCategory]);

  const dropdownOptionFormat = (options: { value: string; name: string }[]) => {
    if (options) {
      const newOptions = options.map(item => {
        return {
          label: item.name,
          object: {
            ID: item.value,
            Description: item.name,
          },
        };
      });
      return newOptions;
    }
    return [];
  };

  const dropdownOptionFormatForAuditType = (options: { value: string; name: string }[]) => {
    if (options) {
      const newOptions = options.map(item => {
        return {
          disabled: !auditTypeViewEditPermission(item.name, user.userInfo).canEdit,
          label: item.name,
          object: {
            ID: item.value,
            Description: item.name,
          },
        };
      });
      return newOptions;
    }
    return [];
  };

  const handleChange = (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
    setFormValues({
      ...existingFormValues,
      [name]: value,
    });
    switch (name) {
      case 'pType':
        setDefaults(value);
        break;
      case 'pCategory':
        setFormValues({
          ...existingFormValues,
          [name]: value,
          pSubCategory: null,
          pGroup: categoryOptions?.find(item => item.name === value.label)?.bucketDescription,
        });
        setValue('pSubCategory', null);
        setValue('pGroup', categoryOptions?.find(item => item.name === value.label)?.bucketDescription);
        break;
      case 'pStatus':
        const selectedAuditType = existingFormValues?.pType?.label;
        if (
          value.label === StatusTypes.ongoing &&
          !(
            selectedAuditType === AuditType.dns ||
            selectedAuditType === AuditType.applicationReferencing ||
            selectedAuditType === AuditType.experienceTouchpoint
          )
        ) {
          setFormValues({
            ...existingFormValues,
            [name]: value,
            pHoldFileOut: true,
          });
          setValue('pHoldFileOut', true);
        } else {
          setFormValues({
            ...existingFormValues,
            [name]: value,
            pHoldFileOut: false,
          });
          setValue('pHoldFileOut', false);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Grid container direction="column" className={classes.root} spacing={3}>
      <Grid item xs={12} className={classes.gridItem}>
        <Grid container direction="row" spacing={1} justifyContent="space-between">
          <Grid item xs={5}>
            <ControlledTypeAhead
              name={'pType'}
              label={`${t('candidate.auditCard.type')} *`}
              register={register}
              control={control}
              options={dropdownOptionFormatForAuditType(lookupSelection?.auditType)}
              onChange={value => handleChange('pType', value)}
              mandatory={true}
              isError={errors.pType}
              showDropdownIcon
              disabled={!addMode || disabled}
              customInputClass={!addMode && classes.disabledInput}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Grid container direction="row" spacing={1} justifyContent="space-between">
          <Grid item xs={5}>
            <ControlledTypeAhead
              name={'pCategory'}
              label={`${t('candidate.auditCard.category')} *`}
              register={register}
              control={control}
              options={dropdownOptionFormat(categoryOptions) || []}
              onChange={value => handleChange('pCategory', value)}
              isOptionsLoading={loading.category}
              mandatory={true}
              disabled={!existingFormValues.pType || disabled}
              isError={errors.pCategory}
              showDropdownIcon
              customInputClass={!existingFormValues.pType && classes.disabledInput}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Grid container direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <Grid item xs={5}>
            <ControlledTypeAhead
              name={'pSubCategory'}
              label={
                existingFormValues.isSubCategoryRequired
                  ? `${t('candidate.auditCard.subCategory')} *`
                  : t('candidate.auditCard.subCategory')
              }
              register={register}
              control={control}
              options={subCategoryOptions}
              onChange={value => handleChange('pSubCategory', value)}
              isOptionsLoading={loading.subCategory}
              mandatory={true}
              disabled={!existingFormValues.pType || !existingFormValues.pCategory || disabled}
              isError={errors.pSubCategory}
              showDropdownIcon
              customInputClass={(!existingFormValues.pType || !existingFormValues.pCategory) && classes.disabledInput}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="row" spacing={2} alignItems="center" xs={12}>
              <Grid item xs={9}>
                <ControlledTypeAhead
                  name={'pPrimaryResponsibleParty'}
                  label={`${t('candidate.auditCard.primaryResponsibleParty')} *`}
                  register={register}
                  control={control}
                  options={dropdownOptionFormat(lookupSelection?.auditResponsible)}
                  onChange={value => handleChange('pPrimaryResponsibleParty', value)}
                  mandatory={true}
                  disabled={!existingFormValues.pType || disabled}
                  isError={errors.pPrimaryResponsibleParty}
                  showDropdownIcon
                  customInputClass={!existingFormValues.pType && classes.disabledInput}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Grid container direction="row" spacing={1} alignItems="baseline" justifyContent="space-between">
          <Grid item xs={5}>
            <ControlledTypeAhead
              name={'pAction'}
              label={`${t('candidate.auditCard.action')} *`}
              register={register}
              control={control}
              options={dropdownOptionFormat(lookupSelection?.auditAction)}
              onChange={value => handleChange('pAction', value)}
              mandatory={true}
              disabled={!existingFormValues.pType || disabled}
              isError={errors.pAction}
              showDropdownIcon
              customInputClass={!existingFormValues.pType && classes.disabledInput}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="row" spacing={2} alignItems="center" xs={12}>
              <Grid item xs={9}>
                <ControlledTypeAhead
                  name={'pStatus'}
                  label={`${t('candidate.auditCard.status')} *`}
                  register={register}
                  control={control}
                  options={dropdownOptionFormat(lookupSelection?.auditStatus)}
                  onChange={value => handleChange('pStatus', value)}
                  mandatory={true}
                  disabled={!existingFormValues.pType || disabled}
                  isError={errors.pStatus}
                  showDropdownIcon
                  customInputClass={!existingFormValues.pType && classes.disabledInput}
                />
              </Grid>
              <Grid item xs={3}>
                <FormInputCheckbox
                  name={'pHoldFileOut'}
                  isChecked={existingFormValues.pHoldFileOut || false}
                  handleChecked={e =>
                    setFormValues({ ...existingFormValues, pHoldFileOut: !existingFormValues.pHoldFileOut })
                  }
                  checkboxLabel={t('candidate.auditCard.holdFileOut')}
                  isDisabled={!existingFormValues.pType || disabled}
                  labelStyle={classes.checkboxLabelStyle}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Grid container direction="row" spacing={1} alignItems="baseline" justifyContent="space-between">
          <Grid item xs={12}>
            <CustomFormInputText
              name="pDescription"
              label={t('candidate.auditCard.description')}
              handleChange={event =>
                handleChange('pDescription', typeof event === 'string' ? event : event.target.value)
              }
              inputValue={getValues('pDescription') || ''}
              message={errors['pDescription']?.message || ''}
              readOnly={!existingFormValues.pType || disabled}
              customInputClass={!existingFormValues.pType && classes.disabledInput}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
