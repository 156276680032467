import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import React, { FC } from 'react';
import LogHeader from './LogHeader';
import LogNotes from './LogNotes';
import { Box, Grid, Typography } from 'amn-ui-core';
import { SnackbarConfirmation } from '@AMIEWEB/Notification/Common/CloseDialog';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSelection, selectVoiceDisposition, selectVoiceLoader } from 'store/redux-store/notification/notification.selector';
import { theme } from 'styles/global-styles';
import { ChangeBanner } from '@AMIEWEB/Notification/Common/ChangeBanner';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { ChannelType, ICallOutcome, UseType } from '@AMIEWEB/Notification/Constants';
import { OutcomeIcon } from './OutcomeGridIcons';
import Outcome from './Outcome';
import styledComponent from 'styled-components';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { voiceCloseModalData } from './Helper';
import { CustomBackdrop } from '@AMIEWEB/Common/Backdrop/CustomBackdrop';

const useStyles = makeStyles()(theme => ({
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '540px',
      overflow: 'hidden',
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
    overflow: 'hidden',
  },
  modalContent: {
    color: theme.palette.framework.system.charcoal,
    padding: '10px 24px',
  },
  snackBar: {
    position: 'absolute',
    zIndex: 2,
    bottom: 20,
  },
  outcomeContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '10px',
  },
}));

interface VoiceDispositionProps {
  addToSummary: boolean;
  summaryCheckboxEnabled: boolean;
  notes: string;
  setNotes: (value: string) => void;
  setAddToSummary: (value: boolean) => void;
  setSummaryCheckboxEnabled: (value: boolean) => void;
  handleMinimize: () => void;
  handleDisposition: (outcomeValue: ICallOutcome, key: string) => void;
  saveManualCallLog: () => void;
}

const FieldHeader = styledComponent(Typography)`
  margin-bottom: 1%;
  margin-top: 2%;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  font: normal normal medium 16px/26px Roboto;
  letter-spacing: 0px;
  color: ${theme.palette.framework.system.charcoal};
  opacity: 1;
`;

const VoiceDisposition: FC<VoiceDispositionProps> = props => {
  const {
    addToSummary,
    summaryCheckboxEnabled,
    notes,
    setNotes,
    setAddToSummary,
    setSummaryCheckboxEnabled,
    handleMinimize,
    handleDisposition,
    saveManualCallLog,
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const globalData = useSelector(notificationSelection);
  const data = globalData?.voice?.data;
  const dispatch = useDispatch();
  const voiceDisposition = useSelector(selectVoiceDisposition);
  const loader = useSelector(selectVoiceLoader);

  const outcomeHandler = (outcomeValue: ICallOutcome) => {
    handleDisposition(outcomeValue, 'disposition');
  };

  const handleModalClose = () => {
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        ...voiceCloseModalData,
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
  };

  return (
    <React.Fragment>
      <GenericDialog
        open
        draggable
        disablePortal
        disableEscapeKeyDown
        dialogTitleProps={{
          text: t('notification.voice.log.title'),
          expandable: false,
          closeButton: false,
          minimize: true,
          onMinimize: handleMinimize,
        }}
        dialogContentProps={{
          classes: { root: classes.modalContent },
        }}
        dialogActions={[
          {
            text: t('notification.voice.log.save'),
            variant: 'contained',
            color: 'primary',
            disabled: voiceDisposition?.disposition === null,
            onClick: () => saveManualCallLog(),
            tooltipProps: voiceDisposition?.disposition === null
            ? {
                tooltipContent: t('notification.voice.saveButtontooltip'),
              }
            : undefined,
          },
        ]}
        fullWidth={true}
        className={classes.modalContainer}
        variant={'blue'}
      >
        {loader && <CustomBackdrop open />}
        {globalData?.snackbarData?.channel === ChannelType.voice &&
          !globalData?.snackbarData?.manual &&
          globalData?.snackbarData?.changeWarning && <ChangeBanner message={t('notification.finishLogWarning')} />}
        <LogHeader />
        <div className={classes.modalContent}>
          <FieldHeader>{t('notification.voice.manualLog.outcome')}</FieldHeader>
          <Grid container gap={2.5} className={classes.outcomeContainer}>
            {OutcomeIcon(data?.useType === UseType.ClientContacts).map(item => (
              <Grid key={item.name} item>
                <Outcome
                  outcomeIcon={item.icon}
                  outcomeName={item.name}
                  outcomeValue={item.value}
                  disposition={voiceDisposition}
                  outcomeHandler={outcomeHandler}
                />
              </Grid>
            ))}
          </Grid>
          <FieldHeader>{t('notification.voice.manualLog.addNote')}</FieldHeader>
          <LogNotes
            logNotes={voiceDisposition.notes}
            handleDisposition={handleDisposition}
            notes={notes}
            setNotes={setNotes}
            setAddToSummary={setAddToSummary}
            setSummaryCheckboxEnabled={setSummaryCheckboxEnabled}
          />
          {globalData?.voice?.displayAddToSummary && (
            <Grid style={{ padding: '10px' }}>
              <SimpleCheckbox
                id={'candidateSummaryCallLogCheckbox'}
                isChecked={addToSummary}
                isdisabled={!summaryCheckboxEnabled}
                hasRadio={false}
                handleChecked={() => setAddToSummary(!addToSummary)}
                checkboxLabel={t('notification.voice.addToSummary')}
                checkboxName={'candidateSummaryCallLogCheckbox'}
              />
            </Grid>
          )}
        </div>
        <Box className={classes.snackBar}>
          {globalData?.snackbarData?.channel === ChannelType.voice &&
            !globalData?.snackbarData?.manual &&
            !globalData?.snackbarData?.changeWarning && (
              <SnackbarConfirmation
                showWarning
                snackbarText={t('notification.voice.manualLog.closeConfirmation')}
                yesAction={handleModalClose}
                noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
              />
            )}
        </Box>
      </GenericDialog>
    </React.Fragment>
  );
};

export default VoiceDisposition;
