import { candidateSearchFilterName } from '@AMIEWEB/GlobalSearch/Candidate/SearchUtils';
import { instanceOfDateRangePicker, instanceOfDateRangePickerSingle } from 'app/ComponentLibrary/DateRangeNew';
import { instanceOfTypeAhead } from 'app/ComponentLibrary/Filter/AsyncTypeAhead';
import { instanceOfAggregateCandidate } from 'app/ComponentLibrary/Filter/CandidateSearch';
import { instanceOfCandidateSkillSetFilter } from 'app/ComponentLibrary/Filter/CandidateSkillset';
import { getNumberRange } from 'app/ComponentLibrary/Filter/CustomNumberRange';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import {
  filterChoiceWithNewOptions,
  getDateRangeLabel,
  getValidFilterSelectionByChip,
  isDataIncluded,
  isItemIncluded,
  isSubstringIncluded,
  itemIsInRange,
} from 'app/ComponentLibrary/Filter/helper';
import { instanceOfINumberRange } from 'app/ComponentLibrary/NumberRange/NumberRange';
import { ITreeView, instanceOfTreeView } from 'app/components/Common/TreeView/CustomTreeView';
import { convertToFormat } from 'app/helpers/dateHelper';
import i18next from 'i18next';
import moment from 'moment';
import { deepEqual } from 'utils/common/comparison';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { getDateValue, getFirstOfMonth, getLastOfMonth } from 'utils/dates/dateExtraction';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import { amPlacementsGridKeys } from './AmGridHelper';
import { IFilterChipSelected, IFilterSpecs } from './AmPlacementsDesk.redux';
import { amPlacementsFilterName } from './filterUtils';
import { instanceOfAggregateFacility } from 'app/ComponentLibrary/Filter/FacilitySearch/Index';
import { instanceOfAggregateUnit } from '@AMIEWEB/Facility/UnitSearchFilter/UnitIndex';

const RemoveAllOption = filter => filter?.filter(x => x.value !== 'All') ?? [];

export const getFilteredData = (filter, sourceData, chipSelected?: IFilterChipSelected) =>
  sourceData.reduce(
    (resultArray, item) =>
      isDataIncluded(
        item[amPlacementsGridKeys.placementAccountManager],
        chipSelected[amPlacementsFilterName.placementAccountManager] ||
          RemoveAllOption(filter[amPlacementsFilterName.placementAccountManager]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.candidate],
        chipSelected?.candidate || RemoveAllOption(filter[amPlacementsFilterName.candidate]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.recruiter],
        chipSelected?.placementRecruiter || RemoveAllOption(filter[amPlacementsFilterName.placementRecruiter]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.mspClientId],
        chipSelected?.mspClient || RemoveAllOption(filter[amPlacementsFilterName.mspClient]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.contractAffiliateId],
        chipSelected?.contractAffiliate || RemoveAllOption(filter[amPlacementsFilterName.contractAffiliate]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.facilityName],
        chipSelected?.facility || RemoveAllOption(filter[amPlacementsFilterName.facility]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.facilityCity],
        chipSelected?.city || RemoveAllOption(filter[amPlacementsFilterName.city]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.facilityState],
        chipSelected?.state || RemoveAllOption(filter[amPlacementsFilterName.state]),
      ) &&
      isSubstringIncluded(
        item[amPlacementsGridKeys.skillSet].split('-')[0],
        chipSelected?.discipline || RemoveAllOption(filter[amPlacementsFilterName.discipline]),
      ) &&
      isSubstringIncluded(
        item[amPlacementsGridKeys.skillSet].split('-')[1],
        chipSelected?.specialty || RemoveAllOption(filter[amPlacementsFilterName.specialty]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.staffingVendorId],
        chipSelected?.staffingVendor || RemoveAllOption(filter[amPlacementsFilterName.staffingVendor]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.technologyVendorId],
        chipSelected?.technologyVendor || RemoveAllOption(filter[amPlacementsFilterName.technologyVendor]),
      ) &&
      isItemIncluded(
        item[amPlacementsGridKeys.orderPriorityIds],
        chipSelected?.orderPriorities || RemoveAllOption(filter[amPlacementsFilterName.orderPriorities]),
      ) && // check if filter is applied
      (filter[amPlacementsFilterName.activeCueDateRange]
        ? // check if record is in active cue category
          item[amPlacementsGridKeys.isActiveCue]
          ? // check if choice was "All active" opt or not ==> for "All active" date is undefined
            filter[amPlacementsFilterName.activeCueDateRange].startDate
            ? itemIsInRange(
                item[amPlacementsGridKeys.lastCueDateOnNoFormat],
                filter[amPlacementsFilterName.activeCueDateRange],
              )
            : true
          : false
        : true)
        ? [...resultArray, item]
        : resultArray,
    [],
  );

export const getFilterChipSelected = (values, selectedChips: IFilterChipSelected) => ({
  ...selectedChips,
  [amPlacementsFilterName.candidate]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.candidate],
    selectedChips.candidate,
  ),
  [amPlacementsFilterName.placementRecruiter]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.placementRecruiter],
    selectedChips.placementRecruiter,
  ),
  [amPlacementsFilterName.mspClient]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.mspClient],
    selectedChips.mspClient,
  ),
  [amPlacementsFilterName.contractAffiliate]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.contractAffiliate],
    selectedChips.contractAffiliate,
  ),
  [amPlacementsFilterName.facility]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.facility],
    selectedChips.facility,
  ),
  [amPlacementsFilterName.city]: getValidFilterSelectionByChip(values[amPlacementsFilterName.city], selectedChips.city),
  [amPlacementsFilterName.state]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.state],
    selectedChips.state,
  ),
  [amPlacementsFilterName.discipline]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.discipline],
    selectedChips.discipline,
  ),
  [amPlacementsFilterName.specialty]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.specialty],
    selectedChips.specialty,
  ),
  [amPlacementsFilterName.staffingVendor]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.staffingVendor],
    selectedChips.staffingVendor,
  ),
  [amPlacementsFilterName.technologyVendor]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.technologyVendor],
    selectedChips.technologyVendor,
  ),
  [amPlacementsFilterName.orderPriorities]: getValidFilterSelectionByChip(
    values[amPlacementsFilterName.orderPriorities],
    selectedChips.orderPriorities,
  ),
});

/////////// saga helper ////////////////
export const getAccountManagerOpts = (recruiters, userInfo) =>
  [
    {
      id: userInfo.employeeId,
      name: Concatenate([userInfo.firstName, userInfo.lastName], ' '),
      value: userInfo.employeeId,
      object: {
        userId: userInfo.employeeId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      },
    },
    ...recruiters.reduce(
      (list, manager) =>
        manager.userId !== userInfo.employeeId
          ? [
              ...list,
              {
                id: manager.userId,
                name: Concatenate([manager.firstName, manager.lastName], ' '),
                value: manager.userId,
                object: manager,
              },
            ]
          : list,
      [],
    ),
  ] as { id: number; name: string; value: number; object: any }[];

export const determineChipSelected = (newChip, selected) =>
  newChip.isSelected
    ? null
    : Array.isArray(newChip.value) && newChip.value.length > 0 && instanceOfTreeView(newChip.value[0])
    ? newChip.value
    : [instanceOfISelection(newChip) ? newChip : newChip.value];

const generateTreeValue = (valueArray: ITreeView[]) => {
  let newValue: ITreeView[] = [];
  do {
    let val: any = undefined;
    if (newValue.length === 0) val = valueArray.shift();
    else val = newValue;
    if (val) {
      newValue = [
        {
          ...valueArray[0],
          children: Array.isArray(val) ? [...val] : [val],
        },
      ];
    }
    valueArray.shift();
  } while (Array.isArray(valueArray) && valueArray.length > 0);
  return newValue;
};

const generateMultiLevelTypeAheadChips = (values, disableDelete) => {
  if (values?.isLevelSelected) {
    return {
      label: `${values?.level}`,
      enableDelete: disableDelete ? false : true,
      value: values?.value,
    };
  } else {
    const arr = [];
    values?.value?.map(item => {
      item !== 'All' &&
        arr.push({
          label: `${item}`,
          enableDelete: disableDelete ? false : true,
          value: item,
        });
    });
    return arr;
  }
};

function formatDateRangeSingleFilter(range) {
  return convertToFormat(range.startDate, 'MM/DD/YYYY', false);
}
export const generateTreeChips = (
  chipArray: any[] = [],
  value: ITreeView[],
  selectedChips,
  prefixLabel = undefined,
  postfixLabel = undefined,
  parentNode: ITreeView | undefined,
  parentTree: ITreeView[],
  disableDelete?: boolean,
) => {
  if (value.length > 0 && value[0].filterName === candidateSearchFilterName.candidateSkillSet) {
    return candidateSkilsetGenerateTreeChips(
      chipArray,
      value,
      selectedChips,
      prefixLabel,
      postfixLabel,
      parentNode,
      parentTree,
      disableDelete,
    );
  } else {
    return placementStatusGenerateTreeChips(
      chipArray,
      value,
      selectedChips,
      prefixLabel,
      postfixLabel,
      parentNode,
      parentTree,
      disableDelete,
    );
  }
};

export const placementStatusGenerateTreeChips = (
  chipArray: any[] = [],
  value: ITreeView[],
  selectedChips,
  prefixLabel = undefined,
  postfixLabel = undefined,
  parentNode: ITreeView | undefined,
  parentTree: ITreeView[],
  disableDelete?: boolean,
): any[] => {
  value.forEach(item => {
    if (Array.isArray(item.children) && item.children.length > 0) {
      chipArray = placementStatusGenerateTreeChips(
        chipArray,
        item.children,
        selectedChips,
        prefixLabel,
        postfixLabel,
        item,
        parentTree.length > 0 ? [...parentTree, item] : [item],
        disableDelete,
      );
    } else {
      const val = generateTreeValue([...parentTree, item].reverse());
      chipArray.push({
        label: `${parentNode?.name}: ${item.name}`,
        enableDelete: disableDelete ? false : true,
        value: val,
        isSelected: selectedChips?.some(chip => deepEqual(chip, val[0])) || false,
      });
    }
  });
  return chipArray;
};

export const candidateSkilsetGenerateTreeChips = (
  chipArray: any[] = [],
  value: ITreeView[],
  selectedChips,
  prefixLabel = undefined,
  postfixLabel = undefined,
  parentNode: ITreeView | undefined,
  parentTree: ITreeView[],
  disableDelete?: boolean,
): any[] => {
  value.forEach(item => {
    if (item.userChecked) {
      // Check if the node has userChecked set to true
      if (Array.isArray(item.children) && item.children.length > 0) {
        // Generate label for the current node and its ancestors
        const label = generateLabel(parentTree, item);
        chipArray.push({
          label,
          enableDelete: disableDelete ? false : true,
          value: [{ ...parentTree[0], children: [item] }], // Include parent node in the value
          isSelected:
            selectedChips?.some(chip =>
              deepEqual(item.filterName ? chip : [chip], [{ ...parentTree[0], children: [item] }]),
            ) || false,
        });
      } else {
        // Generate label for the current node and its ancestors
        const label = generateLabel(parentTree, item);
        chipArray.push({
          label,
          enableDelete: disableDelete ? false : true,
          value: [{ ...parentTree[0], children: [item] }], // Include parent node in the value
          isSelected:
            selectedChips?.some(chip =>
              deepEqual(item.filterName ? chip : [chip], [{ ...parentTree[0], children: [item] }]),
            ) || false,
        });
      }
    }
    if (Array.isArray(item.children) && item.children.length > 0) {
      chipArray = candidateSkilsetGenerateTreeChips(
        chipArray,
        item.children,
        selectedChips,
        prefixLabel,
        postfixLabel,
        item,
        parentTree.length > 0 ? [...parentTree, item] : [item],
        disableDelete,
      );
    }
  });
  return chipArray;
};

// Function to generate label for a node and its ancestors
const generateLabel = (parentTree: ITreeView[], node: ITreeView): string => {
  const labels = parentTree.map(parent => parent.name); // Get names of all ancestors
  labels.push(node.name); // Add current node's name
  return `Skill Set: ${labels.join('-')}`; // Concatenate names with hyphens to form the label
};
const getCandidateRestLabels = customSwitch({
  firstName: 'First Name',
  lastName: 'Last Name',
  goesBy: 'Goes By',
  cid: 'CID',
  otherName: 'Other Name',
  phoneNumber: 'Phone',
  email: 'Email',
})(null);

const getFacilityRestLabels = customSwitch({
  facilityName: 'Facility Name',
  fid: 'FID',
})(null);

const getUnitRestLabels = customSwitch({
  unitName: 'Unit Name',
  unitId: 'UID',
})(null);

export const generateAggregateCandidateChips = (values, selectedChip?, prefixLabel?, postfixLabel?, disableDelete?) => {
  const { selections, ...restOfAggregate } = values;
  const restChips = Object.entries<string>(restOfAggregate).reduce(
    (result, [key, value]) =>
      !!value
        ? [
            ...result,
            {
              label: Concatenate([getCandidateRestLabels(key), value], ': '),
              enableDelete: disableDelete ? false : true,
              value: { key, value },
              isSelected: selectedChip && selectedChip[0]?.key == key,
            },
          ]
        : result,
    [],
  );
  const chips = selections.reduce((result, choice) => {
    const _name = `${choice.name} (${choice.candidateId})`;
    return choice.value === 'All'
      ? result
      : [
          ...result,
          {
            label:
              choice.labelPrefix || prefixLabel
                ? choice.labelPostfix || postfixLabel
                  ? `${choice.labelPrefix ?? prefixLabel}: ${_name} ${choice.labelPostfix ?? postfixLabel}`
                  : `${choice.labelPrefix ?? prefixLabel}: ${_name}`
                : choice.labelPostfix || postfixLabel
                ? `${_name} ${choice.labelPostfix ?? postfixLabel}`
                : _name,
            enableDelete: disableDelete ? false : true,
            value: { key: 'selections', value: choice },
            isSelected: selectedChip?.some(chip => chip.value.value === choice.value) || false,
          },
        ];
  }, restChips);
  return chips;
};

export const generateAggregateFacilityChips = (values, selectedChip?, prefixLabel?, postfixLabel?, disableDelete?) => {
  const { selections, ...restOfAggregate } = values;
  const restChips = Object.entries<string>(restOfAggregate).reduce(
    (result, [key, value]) =>
      !!value
        ? [
            ...result,
            {
              label: Concatenate([getFacilityRestLabels(key), value], ': '),
              enableDelete: disableDelete ? false : true,
              value: { key, value },
              isSelected: selectedChip && selectedChip[0]?.key == key,
            },
          ]
        : result,
    [],
  );
  const chips = selections.reduce((result, choice) => {
    const _name = `${choice.facilityName} (${choice.value})`;
    return choice.value === 'All'
      ? result
      : [
          ...result,
          {
            label:
              choice.labelPrefix || prefixLabel
                ? choice.labelPostfix || postfixLabel
                  ? `${choice.labelPrefix ?? prefixLabel}: ${_name} ${choice.labelPostfix ?? postfixLabel}`
                  : `${choice.labelPrefix ?? prefixLabel}: ${_name}`
                : choice.labelPostfix || postfixLabel
                ? `${_name} ${choice.labelPostfix ?? postfixLabel}`
                : _name,
            enableDelete: disableDelete ? false : true,
            value: { key: 'selections', value: choice },
            isSelected: selectedChip?.some(chip => chip.value.value === choice.value) || false,
          },
        ];
  }, restChips);
  return chips;
};
export const generateAggregateUnitChips = (values, selectedChip?, prefixLabel?, postfixLabel?, disableDelete?) => {
  const { selections, ...restOfAggregate } = values;
  const restChips = Object.entries<string>(restOfAggregate).reduce(
    (result, [key, value]) =>
      !!value
        ? [
            ...result,
            {
              label: Concatenate([getUnitRestLabels(key), value], ': '),
              enableDelete: disableDelete ? false : true,
              value: { key, value },
              isSelected: selectedChip && selectedChip[0]?.key == key,
            },
          ]
        : result,
    [],
  );
  const chips = (selections || [])?.reduce((result, choice) => {
    const _name = `${choice?.unitName} (${choice.value})`;
    return choice.value === 'All'
      ? result
      : [
          ...result,
          {
            label:
              choice.labelPrefix || prefixLabel
                ? `${i18next.t('unitFilter.unit')} ${choice.labelPrefix ?? prefixLabel}: ${_name} ${
                    choice.labelPostfix ?? postfixLabel
                  }`
                  ? `${i18next.t('unitFilter.unit')} {choice.labelPrefix ?? prefixLabel}: ${_name} ${
                      choice.labelPostfix ?? postfixLabel
                    }`
                  : `${i18next.t('unitFilter.unit')}  ${choice.labelPrefix ?? prefixLabel}: ${_name}`
                : choice.labelPostfix || postfixLabel
                ? `${i18next.t('unitFilter.unit')}  ${_name} ${choice.labelPostfix ?? postfixLabel}`
                : `${i18next.t('unitFilter.unit')}  ${_name}`,
            enableDelete: disableDelete ? false : true,
            value: { key: 'selections', value: choice },
            isSelected: selectedChip?.some(chip => chip?.value?.value === choice?.value) || false,
          },
        ];
  }, restChips);

  return chips;
};

const getSkillsetFilterLabels = (key, value) => {
  switch (key) {
    case 'operation':
      return `Grouping: ${value}`;
    case 'allowableMismatch':
      return 'Skill Set: Allowable Mismatch';
    case 'isVerifiedOnly':
      return 'Skill Set: Verified Only';
    case 'isExactMatch':
      return 'Skill Set: Exact Match';
    default:
      return Concatenate([getCandidateRestLabels(key), value], ': ');
  }
};

const showDeleteIcon = (key, disableDelete) => {
  if (key === 'operation') {
    return false;
  }
  return disableDelete ? false : true;
};

export const generateCandidateSkillSetFilterChips = (
  values,
  selectedChip?,
  prefixLabel?,
  postfixLabel?,
  disableDelete?,
) => {
  let { skillsets, ...restFilters } = values;
  skillsets = skillsets.map(item => {
    const filteredChildren = item.children?.filter(child => child.name !== null) || [];
    return {
      ...item,
      children: filteredChildren,
      userChecked: filteredChildren.length === 0 ? true : item.userChecked,
      filterName: 'skillsetFilter',
    };
  });

  let restChips = Object.entries<string>(restFilters).reduce(
    (result, [key, value]) =>
      !!value
        ? [
            ...result,
            {
              label: getSkillsetFilterLabels(key, value),
              enableDelete: showDeleteIcon(key, disableDelete),
              value: { key, value },
              isSelected: false,
            },
          ]
        : result,
    [],
  );
  const chips = generateTreeChips(
    [],
    skillsets || [],
    selectedChip,
    prefixLabel,
    postfixLabel,
    undefined,
    [],
    disableDelete,
  );
  // Remove grouping chip if <2 chips remaining
  if (chips?.length < 2) {
    restChips = restChips?.filter(chip => !chip?.label?.includes('Grouping'));
  }
  if (chips?.length === 0) {
    restChips = [];
  }
  const combinedChips = restChips?.concat(chips);
  return combinedChips;
};

export const generateFieldChips = (values, selectedChip?, prefixLabel?, isDisable?, postfixLabel?, disableDelete?) => {
  if (!values) return [];
  return Array.isArray(values)
    ? values.length
      ? instanceOfTreeView(values[0])
        ? generateTreeChips([], values, selectedChip, prefixLabel, postfixLabel, undefined, [], disableDelete)
        : instanceOfAggregateCandidate(values[0])
        ? generateAggregateCandidateChips(values, selectedChip, prefixLabel, postfixLabel, disableDelete)
        : instanceOfAggregateFacility(values[0])
        ? generateAggregateFacilityChips(values, selectedChip, prefixLabel, postfixLabel, disableDelete)
        : instanceOfAggregateUnit(values[0])
        ? generateAggregateUnitChips(values, selectedChip, prefixLabel, postfixLabel, disableDelete)
        : instanceOfTypeAhead(values[0])
        ? values.reduce(
            (result, choice) => [
              ...result,
              {
                label:
                  choice.labelPrefix || prefixLabel
                    ? choice.labelPostfix || postfixLabel
                      ? `${choice.labelPrefix ?? prefixLabel}: ${choice.object.name} ${
                          choice.labelPostfix ?? postfixLabel
                        }`
                      : `${choice.labelPrefix ?? prefixLabel}: ${choice.object.name}`
                    : choice.labelPostfix || postfixLabel
                    ? `${choice.object.name} ${choice.labelPostfix ?? postfixLabel}`
                    : choice.object.name,
                enableDelete: disableDelete ? false : true,
                value: choice,
                isSelected: selectedChip?.some(chip => chip.object.value === choice.object.value) || false,
              },
            ],
            [],
          )
        : instanceOfISelection(values[0])
        ? values.reduce(
            (result, choice) =>
              choice.value === 'All'
                ? result
                : !choice.isCompactOpt
                ? [
                    ...result,
                    {
                      label:
                        choice.labelPrefix || prefixLabel
                          ? choice.labelPostfix || postfixLabel
                            ? `${choice.labelPrefix ?? prefixLabel}: ${choice.name} ${
                                choice.labelPostfix ?? postfixLabel
                              }`
                            : `${choice.labelPrefix ?? prefixLabel}: ${choice.name}`
                          : choice.labelPostfix || postfixLabel
                          ? `${choice.name} ${choice.labelPostfix ?? postfixLabel}`
                          : choice.name,
                      enableDelete: disableDelete ? false : true,
                      value: choice,
                      isSelected: selectedChip?.some(chip => chip.value === choice.value) || false,
                    },
                  ]
                : result,
            [],
          )
        : values.reduce(
            (result, choice) =>
              choice === 'All'
                ? result
                : [
                    ...result,
                    {
                      label: choice,
                      enableDelete: disableDelete ? false : !isDisable,
                      value: choice,
                      isSelected: selectedChip?.includes(choice) || false,
                    },
                  ],
            [],
          )
      : []
    : values !== null
    ? instanceOfCandidateSkillSetFilter(values)
      ? generateCandidateSkillSetFilterChips(values, selectedChip, prefixLabel, postfixLabel, disableDelete)
      : instanceOfAggregateCandidate(values)
      ? generateAggregateCandidateChips(values, selectedChip, prefixLabel, postfixLabel, disableDelete)
      : instanceOfAggregateFacility(values)
      ? generateAggregateFacilityChips(values, selectedChip, prefixLabel, postfixLabel, disableDelete)
      : instanceOfAggregateUnit(values)
      ? generateAggregateUnitChips(values, selectedChip, prefixLabel, postfixLabel, disableDelete)
      : moment.isDate(values)
      ? {
          label: `${prefixLabel}: ${GetMomentOfDate(values).format('YYYY-MM-DD')}`,
          enableDelete: disableDelete ? false : true,
        }
      : Object.hasOwn(values, 'level') && Object.hasOwn(values, 'value')
      ? generateMultiLevelTypeAheadChips(values, disableDelete)
      : values.type && values.type === 'NestedFilter'
      ? values.nestedlabelPrefix && values.nestedlabelPrefix === 'Exp'
        ? values.value.map(x => {
            const res: string[] = [];
            if (!!x.yearsOfExperience && !!x.yearsOfExperience.value) {
              res.push((x.yearsOfExperience.value.max ? x.yearsOfExperience.value.max : '12+') + 'y');
            }
            if (!!x.state && !!x.state.value) {
              res.push(x.state.value);
            }
            if (!!x.facilityId && !!x.facilityId.value) {
              res.push('FID ' + x.facilityId.value);
            }
            if (!!x.disciplineId && !!x.disciplineId.value && !!x.unitSpecialtyId && !!x.unitSpecialtyId.value) {
              res.push(x.disciplineId.name + '/' + x.unitSpecialtyId.name);
            } else if (!!x.disciplineId && !!x.disciplineId.value) {
              res.push(x.disciplineId.name);
            } else if (!!x.unitSpecialtyId && !!x.unitSpecialtyId.value) {
              res.push(x.unitSpecialtyId.name);
            }
            if (!!x.travelExperience && !!x.travelExperience.value) {
              res.push('TE: ' + x.travelExperience.name);
            }
            return {
              label: values.nestedlabelPrefix ? values.nestedlabelPrefix + ': ' + res.join(' - ') : res.join(' - '),
              enableDelete: disableDelete ? false : !isDisable,
              isSelected: false,
            };
          })
        : values.nestedlabelPrefix && values.nestedlabelPrefix === 'Lic'
        ? values.value.map(x => {
            const res: string[] = [];

            if (!!x?.licenseType) {
              const licenseTypePrefix = x?.licenseType?.labelPrefix ? x?.licenseType?.labelPrefix + ': ' : '';
              const licenseTypeValue = x?.licenseType?.useLabelName ? x?.licenseType?.name : x?.licenseType?.value;
              res.push(licenseTypePrefix + licenseTypeValue);
            }

            if (!!x?.compactStatus && x?.compactStatus?.value) {
              res.push('Compact');
            }

            if (!x?.compactStatus?.value && !!x?.state) {
              const statePrefix = x?.state?.labelPrefix ? x?.state.labelPrefix + ': ' : '';
              const stateValue = x?.state?.useLabelName ? x?.state?.name : x?.state?.value;
              res.push(statePrefix + stateValue);
            }

            if (!!x?.expirationDate) {
              const expirationDatePrefix = x?.expirationDate.labelPrefix ? x?.expirationDate.labelPrefix + ': ' : '';
              res.push(expirationDatePrefix + formatDateRangeSingleFilter(x?.expirationDate));
            }

            return {
              label: values?.nestedlabelPrefix ? values.nestedlabelPrefix + ': ' + res.join(' - ') : res.join(' - '),
              enableDelete: disableDelete ? false : !isDisable,
              isSelected: false,
            };
          })
        : values.nestedlabelPrefix && values.nestedlabelPrefix === 'Facility Type'
        ? values.value.map(x => {
            const res: string[] = [];
            if (!!x.facilityTypeId && !!x.facilityTypeId.name) {
              res.push(x.facilityTypeId?.name);
            }
            if (!!x.facilityDescriptionId && !!x.facilityDescriptionId.name) {
              res.push(x.facilityDescriptionId?.name);
            }
            return {
              label: values?.nestedlabelPrefix ? values.nestedlabelPrefix + ': ' + res.join(' - ') : res.join(' - '),
              enableDelete: disableDelete ? false : !isDisable,
              isSelected: false,
            };
          })
        : values.value.map(x => {
            const res: string[] = [];
            Object.values(x)
              .sort((a, b) => (a as any).position - (b as any).position)
              .forEach(filterValue => {
                const v = filterValue as any;
                if (Object.hasOwn(v, 'value')) {
                  const prefix = v.labelPrefix ? v.labelPrefix + ': ' : '';
                  const value = v.useLabelName ? v.name : v.value;
                  res.push(prefix + value);
                } else if (Object.hasOwn(v, 'startDate') && !!v.startDate) {
                  const prefix = v.labelPrefix ? v.labelPrefix + ': ' : '';
                  res.push(prefix + formatDateRangeSingleFilter(v));
                }
              });
            return {
              label: values.nestedlabelPrefix ? values.nestedlabelPrefix + ': ' + res.join(' - ') : res.join(' - '),
              enableDelete: disableDelete ? false : !isDisable,
              isSelected: false,
            };
          })
      : instanceOfISelection(values)
      ? {
          label:
            values.labelPrefix || prefixLabel
              ? values.labelPostfix || postfixLabel
                ? `${values.labelPrefix ?? prefixLabel}: ${values.name} ${values.labelPostfix ?? postfixLabel}`
                : `${values.labelPrefix ?? prefixLabel}: ${values.name}`
              : values.labelPostfix || postfixLabel
              ? `${values.name} ${values.labelPostfix ?? postfixLabel}`
              : values.name,
          enableDelete: disableDelete ? false : !isDisable,
          value: values,
        }
      : instanceOfINumberRange(values)
      ? {
          label: `${prefixLabel}: ${getNumberRange(values)} ${postfixLabel || ''}`,
          enableDelete: disableDelete ? false : true,
        }
      : instanceOfDateRangePicker(values) || instanceOfDateRangePickerSingle(values)
      ? {
          label: `${prefixLabel}${prefixLabel ? ': ' : ''}${values.label} ${postfixLabel || ''}`,
          enableDelete: disableDelete ? false : true,
        }
      : values.startDate || values.endDate
      ? {
          label: `${prefixLabel}: ${getDateRangeLabel(values)} ${postfixLabel || ''}`,
          enableDelete: disableDelete ? false : true,
        }
      : {
          label: `${values} ${postfixLabel || ''}`,
          enableDelete: disableDelete ? false : true,
        }
    : Object.hasOwn(values, 'level') && Object.hasOwn(values, 'value')
    ? generateMultiLevelTypeAheadChips(values, disableDelete)
    : null;
};

export const createFilterChips = (filter, selectedChips?: IFilterChipSelected) => ({
  [amPlacementsFilterName.placementAccountManager]: generateFieldChips(
    filter[amPlacementsFilterName.placementAccountManager],
    selectedChips?.placementAccountManager || undefined,
    i18next.t('home.amWorkDesk.placementFilter.accountManager'),
  ),
  [amPlacementsFilterName.candidate]: generateFieldChips(
    filter[amPlacementsFilterName.candidate],
    selectedChips?.candidate || undefined,
  ),
  [amPlacementsFilterName.placementRecruiter]: generateFieldChips(
    filter[amPlacementsFilterName.placementRecruiter],
    selectedChips?.placementRecruiter || undefined,
  ),
  [amPlacementsFilterName.mspClient]: generateFieldChips(
    filter[amPlacementsFilterName.mspClient],
    selectedChips?.mspClient || undefined,
  ),
  [amPlacementsFilterName.contractAffiliate]: generateFieldChips(
    filter[amPlacementsFilterName.contractAffiliate],
    selectedChips?.contractAffiliate || undefined,
  ),
  [amPlacementsFilterName.facility]: generateFieldChips(
    filter[amPlacementsFilterName.facility],
    selectedChips?.facility || undefined,
  ),
  [amPlacementsFilterName.city]: generateFieldChips(
    filter[amPlacementsFilterName.city],
    selectedChips?.city || undefined,
  ),
  [amPlacementsFilterName.state]: generateFieldChips(
    filter[amPlacementsFilterName.state],
    selectedChips?.state || undefined,
  ),
  [amPlacementsFilterName.discipline]: generateFieldChips(
    filter[amPlacementsFilterName.discipline],
    selectedChips?.discipline || undefined,
  ),
  [amPlacementsFilterName.specialty]: generateFieldChips(
    filter[amPlacementsFilterName.specialty],
    selectedChips?.specialty || undefined,
  ),
  [amPlacementsFilterName.staffingVendor]: generateFieldChips(
    filter[amPlacementsFilterName.staffingVendor],
    selectedChips?.staffingVendor || undefined,
  ),
  [amPlacementsFilterName.technologyVendor]: generateFieldChips(
    filter[amPlacementsFilterName.technologyVendor],
    selectedChips?.technologyVendor || undefined,
  ),
  [amPlacementsFilterName.orderPriorities]: generateFieldChips(
    filter[amPlacementsFilterName.orderPriorities],
    selectedChips?.orderPriorities || undefined,
  ),
});

export const createAdvancedFilterChips = (filter, t) => ({
  [amPlacementsFilterName.activeCueDateRange]: filter[amPlacementsFilterName.activeCueDateRange]
    ? {
        label: `${t(`home.amWorkDesk.placementFilter.activeCueDateRange`)}: ${
          filter[amPlacementsFilterName.activeCueDateRange].label
        }`,
        enableDelete: true,
      }
    : null,
});

export const filterUserChoices = (
  filterSpecs: IFilterSpecs,
  values: any,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void,
) => {
  values[amPlacementsFilterName.activeCueDateRange] &&
    setValue(amPlacementsFilterName.activeCueDateRange, values[amPlacementsFilterName.activeCueDateRange], {
      shouldDirty: true,
    });
  return {
    ...values,
    [amPlacementsFilterName.candidate]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.candidate],
      filterSpecs.candidateOpts,
      amPlacementsFilterName.candidate,
      setValue,
    ),
    [amPlacementsFilterName.placementRecruiter]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.placementRecruiter],
      filterSpecs.recruiterOpts,
      amPlacementsFilterName.placementRecruiter,
      setValue,
    ),
    [amPlacementsFilterName.mspClient]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.mspClient],
      filterSpecs.mspClientOpts,
      amPlacementsFilterName.mspClient,
      setValue,
    ),
    [amPlacementsFilterName.contractAffiliate]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.contractAffiliate],
      filterSpecs.affiliateOpts,
      amPlacementsFilterName.contractAffiliate,
      setValue,
    ),
    [amPlacementsFilterName.facility]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.facility],
      filterSpecs.facilityOpts,
      amPlacementsFilterName.facility,
      setValue,
    ),
    [amPlacementsFilterName.city]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.city],
      filterSpecs.cityOpts,
      amPlacementsFilterName.city,
      setValue,
    ),
    [amPlacementsFilterName.state]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.state],
      filterSpecs.stateOpts,
      amPlacementsFilterName.state,
      setValue,
    ),
    [amPlacementsFilterName.discipline]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.discipline],
      filterSpecs.disciplineOpts,
      amPlacementsFilterName.discipline,
      setValue,
    ),
    [amPlacementsFilterName.specialty]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.specialty],
      filterSpecs.specialtyOpts,
      amPlacementsFilterName.specialty,
      setValue,
    ),
    [amPlacementsFilterName.staffingVendor]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.staffingVendor],
      filterSpecs.staffingOpts,
      amPlacementsFilterName.staffingVendor,
      setValue,
    ),
    [amPlacementsFilterName.technologyVendor]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.technologyVendor],
      filterSpecs.technologyOpts,
      amPlacementsFilterName.technologyVendor,
      setValue,
    ),
    [amPlacementsFilterName.orderPriorities]: filterChoiceWithNewOptions(
      values[amPlacementsFilterName.orderPriorities],
      filterSpecs.orderPriorityOpts,
      amPlacementsFilterName.orderPriorities,
      setValue,
    ),
  };
};
export const getActiveCueOpts = customSwitch({
  0: null,
  1: () => ({ startDate: getDateValue(0), endDate: getDateValue(0, true) }),
  2: () => ({ startDate: getDateValue(-2), endDate: getDateValue(0, true) }),
  3: () => ({ startDate: getDateValue(-7), endDate: getDateValue(0, true) }),
  4: () => ({
    startDate: getFirstOfMonth(false),
    endDate: getLastOfMonth(false),
  }),
})(undefined);
