import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import moment from 'moment';
import { missingField } from 'app/constants';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import phoneTypes from 'app/assets/jsons/PhoneTypes.json';
import { isNil } from 'lodash';
import { getFormattedDatePeriod } from 'store/redux-store/candidate-work-experience/helper';

const defaultGridColumn = {
  ...XGridDefaultColumn,
  flex: 0,
};

export enum contactTypeIds {
  CELL_PHONE = 6,
  HOME = 5,
  UNIT = 2,
  MAIN_FACILITY = 9,
  OFFICE = 1,
}

export enum contactPreferenceTypeIds {
  Call = 1,
  Email = null,
}

export const contactPreferences = [
  {
    ID: 1, //Syncs with ID of Type "OK To Call"
    Description: 'Call',
  },
  {
    ID: 2, // ID equivalent to Type Email
    Description: 'Email',
  },
];

export const REGEX_PHONE_NUMBER_COUNTRY_CODE = /^\+\d*(?:\s+|$)/;

export const getEvaluationColumns = () => {
  return [
    {
      ...defaultGridColumn,
      field: 'categoryType',
      headerName: 'Category',
      align: 'left',
      flex: 1,
    },
    {
      ...defaultGridColumn,
      field: 'rating',
      headerName: 'Rating',
      align: 'left',
      width: 100,
      valueGetter: ({ value }) => value || missingField,
    },
  ];
};

export const getActivityColumns = () => {
  return [
    {
      ...defaultGridColumn,
      field: 'dateCreated',
      headerName: 'Date/Time',
      align: 'left',
      width: 195,
      valueGetter: ({ value }) =>
        moment(value).isValid() ? moment(value).format('MM/DD/YYYY hh:mm:ss A') : missingField,
    },
    {
      ...defaultGridColumn,
      field: 'userName',
      headerName: 'User',
      align: 'left',
      width: 100,
      valueGetter: ({ value }) => value || missingField,
    },
    {
      ...defaultGridColumn,
      field: 'phoneType',
      headerName: 'Phone Type',
      align: 'left',
      width: 100,
      valueGetter: ({ value }) => value || missingField,
    },
    {
      ...defaultGridColumn,
      field: 'callType',
      headerName: 'Type',
      align: 'left',
      width: 100,
      valueGetter: ({ value }) => value || missingField,
    },
    {
      ...defaultGridColumn,
      field: 'callReason',
      headerName: 'Reason',
      align: 'left',
      width: 100,
      valueGetter: ({ value }) => value || missingField,
    },
    {
      ...defaultGridColumn,
      field: 'callOutcome',
      headerName: 'Outcome',
      align: 'left',
      width: 100,
      valueGetter: ({ value }) => value || missingField,
    },
    {
      ...defaultGridColumn,
      field: 'notes',
      headerName: 'Notes',
      align: 'left',
      width: 200,
      valueGetter: ({ value }) => value || missingField,
    },
  ];
};

export const hasAccess = (user: any) => {
  if (
    Authorized(
      [
        userRoles.recruitment,
        userRoles.recruitment_TeamMember,
        userRoles.recruitment_Leadership,
        userRoles.clinical_Director,
        userRoles.clinical_Manager,
        userRoles.clinical_QualificationsSpecialist,
        userRoles.clinical_ReviewAnalyst,
        userRoles.credentialing,
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
      ],
      user.userInfo,
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const getTransformedReferenceList = (data: any, translation: (text: string) => void) => {
  if (!data?.length) return [];

  const newData = data.map(item => {
    const newPhoneData = {
      phoneType1: null,
      phoneNumber1: null,
      phoneType2: null,
      phoneNumber2: null,
    };

    if (Array.isArray(item.phoneNumbers) && item.phoneNumbers?.length) {
      item.phoneNumbers.forEach((phoneNumber, index) => {
        const phoneTypeKey = `phoneType${index + 1}`;
        const phoneNumberKey = `phoneNumber${index + 1}`;

        newPhoneData[phoneTypeKey] = phoneNumber?.phoneTypeID
          ? phoneTypes?.find(item => item.ID === phoneNumber.phoneTypeID)?.Description
          : phoneNumber?.phoneTypeDescription;
        newPhoneData[phoneNumberKey] = phoneNumber?.phoneNumber;
      });
    }

    const [clinicians, patients] = item.avgPatientCaseLoad?.trim()?.split(':') || [];
    const datePeriod = getFormattedDatePeriod(item.changedDate);

    return {
      ...item,
      ...newPhoneData,
      isNewReference: false,
      isExpanded: false,
      cardTitle: `${item.supervisorName}${item.title ? ` - ${item.title}` : ''}`,
      lastUpdatedText: item.changedByUser?.trim()
        ? `Changed by ${item.changedByUser.trim()}${datePeriod ? ` - ${datePeriod} ago` : ''}`
        : translation('candidate.workExperience.addEditReference.referenceContacts.newReferenceLastUpdatedDefaultText'),
      okToContact: !isNil(item.okToContact)
        ? item.okToContact
        : item.bestTimeToReachID && item.bestTimeToReach
        ? true
        : false,
      contactMethod: !isNil(item.contactMethod)
        ? item.contactMethod
        : item.contactPreferenceTypeID
        ? contactPreferences?.find(x => x.ID === item.contactPreferenceTypeID)?.Description
        : item.email && item.phoneNumbers?.length
        ? 'Email'
        : null,
      numberOfClinicians: !isNil(clinicians) ? clinicians?.trim() : null,
      numberOfPatients: !isNil(patients) ? patients?.trim() : null,
    };
  });

  return newData?.filter((x: any) => !x.isNewReference);
};
