import React, { useEffect, useState } from 'react';
import CustomCollapsible from '../../../../../../Common/CustomCollapsible';
import {
  workExperienceActions,
  workExperienceReducer,
  workExperienceSliceKey,
} from 'store/redux-store/candidate-work-experience/slice';
import { FormProvider, useForm } from 'react-hook-form';
import bestTimesToReach from 'app/assets/jsons/BestTimeToReach.json';
import contactTypes from 'app/assets/jsons/PhoneTypes.json';
import { DropDownItem } from 'app/models/DropDown';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Chip, CircularProgress, Divider } from 'amn-ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { IPostReferenceWorkExpModalPayload } from 'store/redux-store/candidate-work-experience/types';
import { useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { workExperienceSaga } from 'store/redux-store/candidate-work-experience/saga';
import { usePromiseTracker } from 'react-promise-tracker';
import _ from 'lodash';
import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import { useTranslation } from 'react-i18next';

import { REGEX_PHONE_NUMBER_COUNTRY_CODE, contactPreferenceTypeIds, contactPreferences, contactTypeIds } from './ReferenceUtils';
import {
  ReferenceAdditionalInfoLoadable,
  ReferenceContactsLoadable,
  ReferenceDetailsLoadable,
} from './ReferenceLoadable';
import { useAddEditReferenceStyle } from './AddEditReference.style';

const AddReferenceModal = ({
  disabledAccess,
  referenceDetails,
  handleOnCancel,
}: {
  referenceDetails: any;
  disabledAccess: boolean;
  handleOnCancel: () => void;
}) => {
  useInjectReducer({ key: workExperienceSliceKey, reducer: workExperienceReducer });
  useInjectSaga({ key: workExperienceSliceKey, saga: workExperienceSaga });

  const { t } = useTranslation();
  const { classes } = useAddEditReferenceStyle();
  const dispatch = useDispatch();
  const formMethods = useForm({ mode: 'all', defaultValues: referenceDetails });
  const {
    watch,
    reset,
    handleSubmit,
    formState: { dirtyFields, errors, touched },
  } = formMethods;
  const params = useParams<{
    travelerId: string;
    brandId: string;
  }>();

  const { promiseInProgress: postReferenceFromModalTracker } = usePromiseTracker({
    area: 'post-reference-work-experience-modal',
    delay: 0,
  });
  const { promiseInProgress: getReferenceByWorkExpTracker } = usePromiseTracker({
    area: 'get-reference-by-work-experience',
    delay: 0,
  });

  const user = useSelector(selectUser);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState<boolean>(false);
  const [phoneTypeOptions, setPhoneTypeOptions] = useState<DropDownItem[]>([]);
  const [contactPreferenceOptions, setContactPreferenceOptions] = useState<DropDownItem[]>([]);
  const [bestTimeToReachOptions, setBestTimeToReachOptions] = useState<DropDownItem[]>([]);

  useEffect(() => {
    const filteredContactTypes = contactTypes
      .filter(item => Object.values(contactTypeIds).includes(item.ID))
      ?.sort((a, b) => (b.Description > a.Description ? -1 : 1));
    setPhoneTypeOptions(filteredContactTypes);
    setContactPreferenceOptions(contactPreferences?.sort((a, b) => (b.Description > a.Description ? -1 : 1)));
    setBestTimeToReachOptions(bestTimesToReach);
  }, []);

  useEffect(() => {
    if (referenceDetails) {
      reset(referenceDetails);
      setIsExpanded(referenceDetails?.isExpanded);
    }
  }, [referenceDetails]);

  const onSubmit = async data => {
    const phoneNumbers = [];
    if (data.phoneNumber1?.replace(REGEX_PHONE_NUMBER_COUNTRY_CODE, '')) {
      phoneNumbers.push({
        phoneTypeID: phoneTypeOptions.find(item => item.Description === data.phoneType1)?.ID || 0,
        phoneNumber: data.phoneNumber1,
        travelerId: parseInt(params?.travelerId),
        travelerReferenceId: 0,
        workHistoryId: referenceDetails?.workHistoryId,
      });
    }
    if (data.phoneNumber2?.replace(REGEX_PHONE_NUMBER_COUNTRY_CODE, '')) {
      phoneNumbers.push({
        phoneTypeID: phoneTypeOptions.find(item => item.Description === data.phoneType2)?.ID || 0,
        phoneNumber: data.phoneNumber2,
        travelerID: parseInt(params?.travelerId),
        travelerReferenceID: 0,
        workHistoryID: referenceDetails?.workHistoryId,
      });
    }

    if (!data.email && phoneNumbers?.length == 0) {
      setDisplayAlert(true);
      return;
    }

    setDisplayAlert(false);

    const contactPreferenceType = {
      OkToCall: 1,
      DoNotCall: 3,
    };

    const payload: IPostReferenceWorkExpModalPayload = {
      travelerID: referenceDetails?.travelerID ?? parseInt(params?.travelerId),
      travelerReferenceID: referenceDetails?.travelerReferenceID ?? null,
      workHistoryID: referenceDetails?.workHistoryID,
      supervisorName: data.supervisorName,
      title: data.title,
      referenceTypeID: null,
      referenceType: null,
      referenceDate: null,
      email: data.email,
      contactPreferenceTypeID: data.okToContact ? contactPreferenceType.OkToCall : contactPreferenceType.DoNotCall,
      contactPreferenceType: data.contactMethod ?? null,
      contactMethodId: contactPreferences?.find(item => item.Description === data.contactMethod)?.ID || null,
      contactMethod: data.contactMethod,
      okToContact: data.okToContact,
      bestTimeToReachID: bestTimesToReach.find(item => item.Description === data.bestTimeToReach)?.ID || null,
      bestTimeToReach: data.bestTimeToReach,
      currentEmployeeID: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      phoneNumbers: phoneNumbers,
      statusID: referenceDetails?.statusID ?? 1, //statusID: 1 = "New"
    }; //To-do : Add other Reference Edit fields from ReferenceDetails and referenceAdditionalInfo

    dispatch(workExperienceActions.postReferenceFromWorkExpModalAction(payload));
  };

  const onError = () => {
    setIsSaveDisabled(true);
  };

  const handleCancelClick = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) {
      setShowDiscardChangesDialog(true);
    } else yesAction();
  };

  const yesAction = () => {
    setIsExpanded(false);
    setIsSaveDisabled(false);
    setDisplayAlert(false);
    reset();
    handleOnCancel?.();
    setShowDiscardChangesDialog(false);
  };
  const noAction = () => {
    setShowDiscardChangesDialog(false);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <CustomCollapsible
            title={referenceDetails?.cardTitle}
            defaultExpanded={referenceDetails?.isNewReference ? true : false}
            isExpanded={isExpanded}
            setExpanded={setIsExpanded}
            showExpandIcon={!referenceDetails?.isNewReference}
            isDisabled={false}
            actionButtons={[
              {
                text: 'Cancel',
                color: 'tertiary',
                variant: 'text',
                onClick: () => handleCancelClick(),
              },
              {
                text: referenceDetails?.isNewReference
                  ? t('candidate.workExperience.addEditReference.referenceContacts.addReferenceButtonText')
                  : t('candidate.workExperience.addEditReference.referenceContacts.updateReferenceButtonText'),
                type: 'submit',
                variant: 'contained',
                color: 'primary',
                disabled: !watch('supervisorName') || disabledAccess || isSaveDisabled || displayAlert,
              },
            ]}
            leftActions={[
              {
                type: 'component',
                renderComponent: referenceDetails?.status ? (
                  <Chip label={referenceDetails.status} variant="outlined" sx={{ height: '30px' }} />
                ) : null,
              },
            ]}
            rightActions={[
              {
                type: 'text',
                text: referenceDetails?.lastUpdatedText,
                textProps: { sx: { paddingRight: referenceDetails?.isNewReference ? 12 : 0 } },
              },
              {
                type: 'icon',
                icon: !referenceDetails?.isNewReference && <DeleteOutlinedIcon />,
                hidden: referenceDetails?.isNewReference || disabledAccess,
                tooltipProps: {
                  disabled: referenceDetails?.isNewReference,
                  tooltipContent: 'Delete',
                },
              },
            ]}
          >
            <ReferenceContactsLoadable
              phoneTypeOptions={phoneTypeOptions}
              contactPreferenceOptions={contactPreferenceOptions}
              bestTimeToReachOptions={bestTimeToReachOptions}
              displayAlert={displayAlert}
              disabled={disabledAccess} //todo
              setSaveDisabled={setIsSaveDisabled}
              setDisplayAlert={setDisplayAlert}
            />
            {(postReferenceFromModalTracker || getReferenceByWorkExpTracker) && (
              <CircularProgress className={classes.MuiCircularProgressRoot} />
            )}

            {!referenceDetails?.isNewReference && (
              <>
                <Divider sx={{ mx: 4, mt: 3 }} />
                <ReferenceDetailsLoadable disabled={disabledAccess || true} />
                <Divider sx={{ mx: 4, mt: 3 }} />
                <ReferenceAdditionalInfoLoadable
                  referenceDetails={referenceDetails}
                  disabled={disabledAccess || true}
                />
              </> /*To-Do: remove the || condition when we enable the reference details/Additional Info Edit on the modal.*/
            )}
          </CustomCollapsible>
        </form>
      </FormProvider>

      {showDiscardChangesDialog && (
        <Cancel openDialog={showDiscardChangesDialog} handleConfirmAction={yesAction} handleCancelAction={noAction} />
      )}
    </>
  );
};

export default AddReferenceModal;
